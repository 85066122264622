import { combineReducers } from 'redux';

import sessionReducer from './reducers/sessionReducer';
import AuthCheckReducer from './Login/reducers';
import uploadModalReducer from './uploadModal/reducer';
import AutoCompleteReducer from './AutoCompletes/reducers';
import uploadAudioAndSubtitleModalReducer from './UploadAudioAndSubtitle/reducers'
import { generalDataReducer } from './GeneralData/reducer';

const rootReducer = combineReducers({
  session: sessionReducer, // not mine
  auth: AuthCheckReducer,
  autoComplete: AutoCompleteReducer,
  uploadModal: uploadModalReducer,
  uploadAudioAndSubtitleModal: uploadAudioAndSubtitleModalReducer,
  generalData: generalDataReducer
});

export default rootReducer;
