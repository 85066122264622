import actionTypes from './actionTypes';

const { SET_STATAIC_PERMISSIONS, SET_SITE_LANGUAGE, SET_CONFIGURATION } = actionTypes;

export const setStaticPermissions = data => ({
    type: SET_STATAIC_PERMISSIONS,
    payload: data,
});

export const setSiteLanguage = siteLanguage => ({
    type: SET_SITE_LANGUAGE,
    payload: siteLanguage,
});

export const setVODConfiguration = config => ({
    type: SET_CONFIGURATION,
    payload: config,
});
