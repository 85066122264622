import uploadAudioAndSubtitleActionTypes from "./actionTypes"

const {
    OPEN_UPLOAD_AUDIO_AND_SUBTITLE_MODAL,
    CLOSE_UPLOAD_AUDIO_AND_SUBTITLE_MODAL,
    UPLOAD_AUDIO_AND_SUBTITLE_COMPLETE,
    OPEN_LIST_OF_EXISTED_AUDIO_AND_SUBTITLE,
    CLOSE_LIST_OF_EXISTED_AUDIO_AND_SUBTITLE,
    MODIFY_EXISTED_LANGUAGES
} = uploadAudioAndSubtitleActionTypes

export const openUploadAudioAndSubModal = (payload) => ({
    type: OPEN_UPLOAD_AUDIO_AND_SUBTITLE_MODAL,
    payload
})

export const uploadSubAndAudioCompleted = () => ({
    type: UPLOAD_AUDIO_AND_SUBTITLE_COMPLETE,
})


export const closeUploadAudioAndSubModal = () => ({
    type: CLOSE_UPLOAD_AUDIO_AND_SUBTITLE_MODAL
})

export const openExistedAudioSubtitleModal = (payload) => ({
    type: OPEN_LIST_OF_EXISTED_AUDIO_AND_SUBTITLE,
    payload
})

export const closeExistedAudioSubtitleModal = () => ({
    type: CLOSE_LIST_OF_EXISTED_AUDIO_AND_SUBTITLE,
})

export const modifyExistedLanguages = (payload) => ({
    type: MODIFY_EXISTED_LANGUAGES,
    payload
})

