import React, { useState, useEffect } from 'react'
import PropTypes, { oneOfType } from 'prop-types'
import AntModal from '../Modal'
import { IconButton, Stack, TextField, Typography } from '@mui/material'
import SearchLanguages from 'myComponents/UseFullAutoCompletes/SearchLanguages'
import Uppy from '@uppy/core'
import Uploader from '../UploadVideoModal/VideoUploader'
import Tus from '@uppy/tus'
import { useUppy } from '@uppy/react'
import getTusUploadId from 'utils/getTusUploadID'
import { API, authHeader } from 'utils/DataService/api'
import { useBeforeunload } from 'react-beforeunload'
import MinimizeIcon from '@mui/icons-material/Minimize';
import CloseIcon from '@mui/icons-material/Close';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { useSelector, useDispatch } from 'react-redux'
import { closeExistedAudioSubtitleModal, closeUploadAudioAndSubModal, modifyExistedLanguages, uploadSubAndAudioCompleted } from 'Redux/UploadAudioAndSubtitle/actions'
import MakeSureClose from './MakeSureClose.modal'
import { checkAudioAndSubtitle, editAudioAndSubtitle, removeAudioAndSubtitle } from 'api/Video/videoApi'
import { toastSuccess, toastWarning } from 'components/Toasts/Toasts'
import RemoveModal from '../RemoveModal'


const UploadSubtitleAndDubbed = () => {
  // in this modal :
  // User can choose the language and Enter the Title and Upload File
  // in Add Mode : User can Upload File
  // in Edit Mode : user Only can : {
  //  1. change the language
  //  2. change title
  //  3. Remove the File
  //  4. Notice that the Upload Button Will Not Displayed in This Mode ( Edit Mode ) 
  //}
  /* ****************************************************** Redux ***************************************************** */
  const { isAudio, sourceId, onDataAddedSuccessfully, selectedLanguage, fileId } = useSelector(state => state.uploadAudioAndSubtitleModal);
  const dispatch = useDispatch();
  const modalTitle = isAudio ? 'Audio (Dub)' : 'Subtitle';

  /* ***************************************************** States ***************************************************** */
  const [dataForm, setDataForm] = useState({
    selectedLanguage: null,
    title: '',
  });
  const [modalWindow, setModalWindow] = useState('NORMAL');
  const [makeSureCloseModal, setMakeSureCloseModal] = useState(false);
  const [uploadStarted, setUploadStarted] = useState(false);
  const [removeModalVisiblity, setRemoveModalVisiblity] = useState(null)
  const [removeLoading, setRemoveLoading] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)

  /* **************************************************** Functions *************************************************** */

  const handleUpdateFileData = () => {
    setUpdateLoading(true)
    const data = {
      isAudio,
      languageId: selectedLanguage.language.id,
      title: dataForm.title
    }

    editAudioAndSubtitle(fileId, data)
      .then(() => {
        toastSuccess('File Updated !')
        setUpdateLoading(false)
        onDataAddedSuccessfully()
        dispatch(closeExistedAudioSubtitleModal())
        dispatch(closeUploadAudioAndSubModal())
      })
      .catch(() => {
        setUpdateLoading(false)
      })
  }

  const removeFile = () => {
    setRemoveLoading(true)
    removeAudioAndSubtitle(fileId)
      .then(() => {
        setRemoveLoading(false)
        toastWarning('file Removed !')
        onDataAddedSuccessfully()
        dispatch(modifyExistedLanguages({
          itemForModify: selectedLanguage,
          modifyType: 'remove'
        }))
        dispatch(closeUploadAudioAndSubModal())
      })
      .catch(() => {
        setRemoveLoading(false)
      })
  }

  const handleRemoveState = (value) => {
    setRemoveModalVisiblity(value)
  }

  const closeModal = () => {
    dispatch(closeUploadAudioAndSubModal())
  };

  const closeModalWhenUploadCompleted = () => {
    dispatch(uploadSubAndAudioCompleted())
  }

  const handleChange = (name, data) => {

    setDataForm(prev => ({
      ...prev,
      [name]: data
    }))

    if (data !== null) {
      uppy.setMeta({
        isAudio,
        sourceId,
        [name === 'selectedLanguage' ? "languageId" : name]: data?.id ?? data,
      });
    }
  };


  const minimize = () => {
    setModalWindow('MINIMIZE');
    dispatch(closeExistedAudioSubtitleModal())
  };

  const maximize = () => {
    setModalWindow('NORMAL');
  };

  const confirmationModal = () => {
    setMakeSureCloseModal(prev => !prev);
  };

  useBeforeunload((event) => {
    if (uploadStarted) {
      event.preventDefault()
    }
  })

  /* *************************************************** INTIAL UPPY ************************************************** */
  const uppy = useUppy(() => {
    return new Uppy({
      id: 'Audio&Sub',
      autoProceed: false, // for Start Upload after user select file
      onBeforeUpload: file => (file),
    })
      .use(Tus, {
        endpoint: `${API}/admin/content/uploads/audioAndSubtitle`,
        headers: authHeader(),
        retryDelays: [0, 1000, 3000, 5000],
      })
      .on('file-removed', () => {
        setUploadStarted(false)
      })
      .on('file-added', file => {
        setUploadStarted(true)
      })
      .on('complete', file => {
        const tusId = getTusUploadId(file.successful[0]);
        checkAudioAndSubtitle(tusId)
          .then((res) => {
            onDataAddedSuccessfully()
            setUploadStarted(false)
            closeModalWhenUploadCompleted()
          })
          .catch((err) => {
            setUploadStarted(false)
            return err
          })
      })
      .on('cancel-all', () => setUploadStarted(false))
      .on('error', () => setUploadStarted(false))
  });

  /* *************************************************** sideEffects ************************************************** */
  useEffect(() => {
    if (selectedLanguage) {
      const { title, language } = selectedLanguage;
      setDataForm((prev) => ({
        ...prev,
        title,
        selectedLanguage: language
      }))
    }
  }, [])

  return (
    <AntModal
      visible={Boolean(sourceId)}
      uploadModal
      fullWidth={modalWindow === 'NORMAL'}
      maxWidth='sm'

      // on OK Edit Mode
      onOkText='Update'
      onOk={handleUpdateFileData}
      okButtonProps={{
        sx: {
          display: !!selectedLanguage ? 'inline-block' : 'none'
        },
        variant: 'contained'
      }}
      loading={updateLoading}

      // on Cancel the Modal
      onCancelText={uploadStarted ? 'Cancel Upload' : 'Cancel'}
      onCancel={uploadStarted ? confirmationModal : closeModal}
      cancelButtonProps={{ color: uploadStarted ? 'error' : 'primary' }}

      // on User Select Remove on Edit Mode
      removeText='Remove File'
      onClickRemove={() => handleRemoveState(true)}
      removeButton={!!selectedLanguage}

      onBackdropClick={uploadStarted ? confirmationModal : closeModal}
      hideBackDrop={modalWindow === 'MINIMIZE'}
      isDraggable={modalWindow === 'MINIMIZE'}
      noAction={modalWindow === 'MINIMIZE'}
      titleFlexDirection="column"
      title={
        // TITLE OF UPLOAD MODAL
        <Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography fontWeight="bold" sx={{ marginRight: 2, flexDirection: 'column' }}>
              {!selectedLanguage ?
                uploadStarted ? `Uploading ${modalTitle} . . .` : `Upload Video ${modalTitle}`
                :
                // if user were in Edit Mode
                `Edit ${modalTitle}`
              }
            </Typography>
            <Stack>
              {modalWindow === 'NORMAL' ? (
                uploadStarted ? (
                  <IconButton onClick={minimize} size="small" edge="start">
                    <MinimizeIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={uploadStarted ? confirmationModal : closeModal} size="small" LinkComponent='hello'>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )
              ) : (
                <Stack direction="row" justifyContent="space-evenly">
                  <IconButton onClick={maximize} size="small">
                    <OpenInFullIcon fontSize="small" />
                  </IconButton>
                  <IconButton onClick={uploadStarted ? confirmationModal : closeModal} size="small">
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      }
    >
      {/*Content of Upload Modal */}
      <Stack justifyContent='center' direction='row'>
        <Stack width={400}>
          {
            modalWindow !== 'MINIMIZE' &&
            <React.Fragment>
              <SearchLanguages
                defaultValue={dataForm.selectedLanguage}
                value={dataForm.selectedLanguage}
                onChange={(e, data) => handleChange('selectedLanguage', data)}
                label='Language of File'
                disabled={uploadStarted}
                required
              />

              <TextField
                defaultValue={dataForm.title}
                onChange={(e) => handleChange('title', e.target.value)}
                label='Title'
                placeholder={`Enter a Title for this ${modalTitle}`}
                disabled={uploadStarted}
                sx={{ mt: 1 }}

              />
            </React.Fragment>
          }
          {
            !selectedLanguage && (
              <Uploader
                fullWidth
                uppy={uppy}
                uploadStarted={uploadStarted}
                disabled={!dataForm.selectedLanguage}
              />
            )
          }
        </Stack>
      </Stack>

      {
        (uploadStarted && makeSureCloseModal) && (
          <MakeSureClose
            onOk={closeModal}
            onCancel={confirmationModal}
          />
        )
      }
      {
        removeModalVisiblity && (
          <RemoveModal
            loading={removeLoading}
            onClose={() => handleRemoveState(false)}
            onOk={removeFile}
          />
        )
      }
    </AntModal>
  )
}

UploadSubtitleAndDubbed.propTypes = {
  onCancel: PropTypes.func,
  isAudio: PropTypes.bool,
  sourceId: oneOfType([PropTypes.string, PropTypes.number])
}

export default UploadSubtitleAndDubbed