/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import {
    AppBar,
    Badge,
    Button,
    IconButton,
    Toolbar,
    Hidden,
    Input,
    colors,
    Popper,
    Paper,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ClickAwayListener,
    Typography,
    Stack,
} from '@mui/material';
import LockIcon from '@mui/icons-material/LockOutlined';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import InputIcon from '@mui/icons-material/Input';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';

import axios from 'utils/DataService/axios';
import useRouter from 'utils/useRouter';
import { PricingModal, NotificationsPopover } from 'components';
import { logOutAsync } from '../../../../Redux/Login/actions';
// import nodioLogo from 'assets/Pictures/Logo.svg';
import LanguageIcon from '@mui/icons-material/Language';
import LanguagePopover from 'components/LanguagePopover';
import { t } from 'i18next';

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
    },
    flexGrow: {
        flexGrow: 1,
    },
    title: {
        height: '4.5rem',
        justifyContent: 'center',
    },
    search: {
        backgroundColor: 'rgba(255,255,255, 0.1)',
        borderRadius: 4,
        flexBasis: 300,
        height: 36,
        padding: theme.spacing(0, 2),
        display: 'none',
        alignItems: 'center',
    },
    searchIcon: {
        marginRight: theme.spacing(2),
        color: 'inherit',
    },
    searchInput: {
        flexGrow: 1,
        color: 'inherit',
        '& input::placeholder': {
            opacity: 1,
            color: 'inherit',
        },
    },
    searchPopper: {
        zIndex: theme.zIndex.appBar + 100,
    },
    searchPopperContent: {
        marginTop: theme.spacing(1),
    },
    trialButton: {
        display: 'none',
        marginLeft: theme.spacing(2),
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900],
        },
    },
    trialIcon: {
        marginRight: theme.spacing(1),
    },
    notificationsButton: {
        marginLeft: theme.spacing(1),
    },
    notificationsBadge: {
        backgroundColor: colors.orange[600],
    },
    logoutButton: {
        marginLeft: theme.spacing(1),
    },
    logoutIcon: {
        marginRight: theme.spacing(1),
    },
}));

const TopBar = props => {
    const { onOpenNavBarMobile, className, ...rest } = props;

    const classes = useStyles();
    const { history } = useRouter();
    const searchRef = useRef(null);
    const dispatch = useDispatch();
    const notificationsRef = useRef(null);
    const languageRef = useRef(null);
    const [pricingModalOpen, setPricingModalOpen] = useState(false);
    const [openSearchPopover, setOpenSearchPopover] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [notifications, setNotifications] = useState([]);
    const [openNotifications, setOpenNotifications] = useState(false);
    const [openLanguage, setOpenLanguage] = useState(false);

    const siteLang = useSelector(state => state.generalData.siteLanguage.lang);

    useEffect(() => {
        let mounted = true;

        const fetchNotifications = () => {
            axios.get('/api/account/notifications').then(response => {
                if (mounted) {
                    setNotifications(response.data.notifications);
                }
            });
        };

        fetchNotifications();

        return () => {
            mounted = false;
        };
    }, []);

    const handleLogout = () => {
        dispatch(logOutAsync());
    };

    const handlePricingOpen = () => {
        setPricingModalOpen(true);
    };

    const handlePricingClose = () => {
        setPricingModalOpen(false);
    };

    const handleNotificationsOpen = () => {
        setOpenNotifications(true);
    };

    const handleNotificationsClose = () => {
        setOpenNotifications(false);
    };

    const handleSearchChange = event => {
        setSearchValue(event.target.value);

        if (event.target.value) {
            if (!openSearchPopover) {
                setOpenSearchPopover(true);
            }
        } else {
            setOpenSearchPopover(false);
        }
    };

    const handleSearchPopverClose = () => {
        setOpenSearchPopover(false);
    };

    const popularSearches = [
        'Devias React Dashboard',
        'Devias',
        'Admin Pannel',
        'Project',
        'Pages',
    ];

    return (
        <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
            <Toolbar>
                <RouterLink to="/">
                    <Stack className={classes.title}>
                        {/* <img src={nodioLogo} alt="Nodio-Logo" /> */}
                    </Stack>
                </RouterLink>
                <div className={classes.flexGrow} />
                <Hidden mdDown>
                    <div className={classes.search} ref={searchRef}>
                        <SearchIcon className={classes.searchIcon} />
                        <Input
                            className={classes.searchInput}
                            disableUnderline
                            onChange={handleSearchChange}
                            placeholder="Search people &amp; places"
                            value={searchValue}
                        />
                    </div>
                    <Popper
                        anchorEl={searchRef.current}
                        className={classes.searchPopper}
                        open={openSearchPopover}
                        transition
                    >
                        <ClickAwayListener onClickAway={handleSearchPopverClose}>
                            <Paper className={classes.searchPopperContent} elevation={3}>
                                <List>
                                    {popularSearches.map(search => (
                                        <ListItem
                                            button
                                            key={search}
                                            onClick={handleSearchPopverClose}
                                        >
                                            <ListItemIcon>
                                                <SearchIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={search} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>
                        </ClickAwayListener>
                    </Popper>
                    <Button
                        className={classes.trialButton}
                        onClick={handlePricingOpen}
                        variant="contained"
                    >
                        <LockIcon className={classes.trialIcon} />
                        Trial expired
                    </Button>
                </Hidden>
                <IconButton color="inherit" ref={languageRef} onClick={() => setOpenLanguage(true)}>
                    <Typography
                        fontWeight={'bold'}
                        color="inherit"
                        sx={{
                            mx: 1,
                        }}
                    >
                        {siteLang.toUpperCase()}
                    </Typography>
                    <LanguageIcon />
                </IconButton>
                <Hidden lgDown>
                    <IconButton
                        className={classes.notificationsButton}
                        color="inherit"
                        onClick={handleNotificationsOpen}
                        ref={notificationsRef}
                        size="large"
                    >
                        <Badge
                            badgeContent={notifications.length}
                            classes={{ badge: classes.notificationsBadge }}
                            variant="dot"
                        >
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <Button className={classes.logoutButton} color="inherit" onClick={handleLogout}>
                        <InputIcon className={classes.logoutIcon} />
                        {t('signOut')}
                    </Button>
                </Hidden>
                <Hidden lgUp>
                    <IconButton color="inherit" onClick={onOpenNavBarMobile} size="large">
                        <MenuIcon />
                    </IconButton>
                </Hidden>
            </Toolbar>
            <PricingModal onClose={handlePricingClose} open={pricingModalOpen} />
            <NotificationsPopover
                anchorEl={notificationsRef.current}
                notifications={notifications}
                onClose={handleNotificationsClose}
                open={openNotifications}
            />
            <LanguagePopover
                anchorEl={languageRef.current}
                open={openLanguage}
                onClose={() => setOpenLanguage(false)}
            />
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onOpenNavBarMobile: PropTypes.func,
};

export default TopBar;
