const actionTypes = {
  OPEN_MODAL: 'OPEN_UPLOAD_VIDEO_MODAL',
  CLOSE_MODAL: 'CLOSE_UPLOAD_VIDEO_MODAL',
  SET_DEFAULT: 'SET_DEFAULT_VALUE',
  OPEN_MODAL_GALLERY: 'OPEN_MODAL_UPLOAD_GALLERY',
  CLOSE_MODAL_GALLERY: 'CLOSE_MODAL_UPLOAD_GALLERY',
  SET_DEFAULT_GALLERY: 'SET_DEFAULT_GALLERY',
  CHANGE_TAB: 'CHANGE_GALLERY_TAB',
  SET_GALLERY: 'SET_GALLERY_LIST',
  SET_PREVIEW_IMAGE: 'SET_PREVIEW_IMAGE',
  UPDATE_VIDEO_LIST: 'UPDATE_VIDEO_LIST',
};

export default actionTypes;
