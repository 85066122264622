import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import PropTypes from 'prop-types'

const CustomTooltip = props => {
  return (
    <Tooltip title={props.title} followCursor leaveDelay={150} placement="top" sx={{ fontSize: props.fontSize }}>
      {props.children}
    </Tooltip>
  );
};

CustomTooltip.propTypes = {
  title: PropTypes.string || PropTypes.object,
};

CustomTooltip.defaultProps = {
  title: '' || {},
};

export default CustomTooltip;

export const HelperHint = props => {
  return (
    <CustomTooltip
      title={!props.importantPart ? props.message :
        <Typography color="#fff" fontSize="14px">
          {props.message} <b>{props.importantPart}</b>
        </Typography>
      }
    >
      <HelpOutline color="primary" />
    </CustomTooltip>
  );
};

HelperHint.propTypes = {
  message: PropTypes.string,
  importantPart: PropTypes.string,
};
