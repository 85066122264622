import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import React from 'react';
import AntModal from './Modal';
import PropTypes from 'prop-types';
import { t } from 'i18next';

const RemoveModal = props => {
    const { name, onClose, onOk, loading } = props;

    return (
        <AntModal
            visible
            fullWidth
            maxWidth="sm"
            title={
                <Stack direction="row" alignItems="center">
                    <InfoOutlined color="error" fontSize="large" />
                    <Typography variant="body1" sx={{ fontWeight: 'bold', ml: 1 }}>
                        {t('Remove')} {name}{' '}
                    </Typography>
                </Stack>
            }
            onCancel={onClose}
            onCancelText={t('Cancel')}
            cancelButtonProps={{
                variant: 'outlined',
                color: 'primary',
            }}
            onOk={onOk}
            onOkText={t('Remove')}
            okButtonProps={{
                variant: 'outlined',
                color: 'error',
                disabled: loading,
                endIcon: loading ? <CircularProgress size={20} color="inherit" /> : null,
            }}
        >
            <Typography component="span">
                {t('Are You Sure do you wanna Remove')}
                <Typography
                    variant="body1"
                    sx={{
                        fontWeight: 'bold',
                        display: 'inline-block',
                        mx: 0.5,
                        textDecoration: 'underline',
                    }}
                    component="span"
                >
                    {name}
                </Typography>
                ?
            </Typography>
        </AntModal>
    );
};

RemoveModal.propTypes = {
    name: PropTypes.string,
    onClose: PropTypes.func,
    onOk: PropTypes.func,
    loading: PropTypes.bool,
};

RemoveModal.defaultProps = {
    name: '',
    loading: false,
    onClose: () => {},
    onOk: () => {},
};

export default React.memo(RemoveModal);
