import uploadAudioAndSubtitleActionTypes from './actionTypes';

const INITAIL_STATE = {
    sourceId: null,
    isAudio: null,
    isUploadModalOpen: false,
    onDataAddedSuccessfully: () => {},
    // This two below States will handle the existed languages Modal
    isExistedAudioSubtitleModalOpen: false,
    existedLanguages: null,
    selectedLanguage: null,
    fileId: null,
};

const {
    OPEN_UPLOAD_AUDIO_AND_SUBTITLE_MODAL,
    UPLOAD_AUDIO_AND_SUBTITLE_COMPLETE,
    CLOSE_UPLOAD_AUDIO_AND_SUBTITLE_MODAL,
    OPEN_LIST_OF_EXISTED_AUDIO_AND_SUBTITLE,
    CLOSE_LIST_OF_EXISTED_AUDIO_AND_SUBTITLE,
    MODIFY_EXISTED_LANGUAGES,
} = uploadAudioAndSubtitleActionTypes;

const uploadAudioAndSubtitleModalReducer = (state = INITAIL_STATE, { type, payload }) => {
    switch (type) {
        case OPEN_UPLOAD_AUDIO_AND_SUBTITLE_MODAL:
            return {
                ...state,
                selectedLanguage: payload ?? null,
                fileId: payload?.id ?? null,
                isUploadModalOpen: true,
            };

        case UPLOAD_AUDIO_AND_SUBTITLE_COMPLETE:
            return {
                ...state,
                ...INITAIL_STATE,
            };

        case CLOSE_UPLOAD_AUDIO_AND_SUBTITLE_MODAL:
            return {
                ...state,
                selectedLanguage: null,
                fileId: null,
                isUploadModalOpen: false,
            };

        case OPEN_LIST_OF_EXISTED_AUDIO_AND_SUBTITLE:
            const { id, isAudio, existedLanguages, onDataAddedSuccessfully } = payload;
            return {
                ...state,
                sourceId: id,
                isAudio: isAudio,
                onDataAddedSuccessfully,
                existedLanguages: existedLanguages,
                isExistedAudioSubtitleModalOpen: true,
            };

        case MODIFY_EXISTED_LANGUAGES:
            const { itemForModify, modifyType } = payload;
            let modifiedData = [];
            if (modifyType === 'add') {
                modifiedData = [...state.existedLanguages, itemForModify];
            }
            if (modifyType === 'remove') {
                modifiedData = state.existedLanguages.filter(item => item.id !== itemForModify.id);
            }
            return {
                ...state,
                existedLanguages: modifiedData,
            };

        case CLOSE_LIST_OF_EXISTED_AUDIO_AND_SUBTITLE:
            return {
                ...state,
                isExistedAudioSubtitleModalOpen: false,
            };

        default:
            return state;
    }
};

export default uploadAudioAndSubtitleModalReducer;
