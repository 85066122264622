import React, { useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import { CircularProgress, Stack, StyledEngineProvider } from '@mui/material';
import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { Provider as StoreProvider, useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ProtectedRoute from 'routes/protectedRoute';
import Auth from 'routes/auth';
import MainRoutes from 'routes/mainRoutes';
import MyTheme from './theme';
import { store, persistor } from './Redux/store';
import * as LocalStorage from './utils/localStorage';
import { ScrollReset, GoogleAnalytics, CookiesNotification } from './components';
import './assets/css/fonts.css';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/index.scss';
import './assets/scss/rtl.scss';
import './assets/scss/app.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    checkIsLoggedInAsync,
    checkIsLoggedInFailed,
} from 'Redux/Login/actions';
import ErrorBoundry from 'components/ErrorBoundry/ErrorBoundry';
import { CacheProvider } from '@emotion/react';
import stylisRTLPlugin from 'stylis-plugin-rtl';
import { prefix, prefixer } from 'stylis';
import createCache from '@emotion/cache';
import { PersistGate } from 'redux-persist/integration/react';
import i18next, { changeLanguage } from 'i18next';

const ConfigProvider = () => {
    const { isLoggedIn } = useSelector(state => state.auth);
    const { siteLanguage } = useSelector(state => state.generalData);
    const dispatch = useDispatch();

    const rtlCache = createCache({
        key: 'muirtl',
        stylisPlugins: [prefixer, stylisRTLPlugin],
    });

    useEffect(() => {
        if (LocalStorage.getItem('ACCESS_TOKEN')) {
            dispatch(checkIsLoggedInAsync());
        } else {
            dispatch(checkIsLoggedInFailed());
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (siteLanguage) {
            document.dir = siteLanguage.dir;
            document.lang = siteLanguage.lang;

            if (siteLanguage.lang !== i18next.language) {
                changeLanguage(siteLanguage.lang);
            }
        }
    }, [siteLanguage]);

    if (isLoggedIn === null) {
        return (
            <Stack
                justifyContent="center"
                justifyItems="space-between"
                alignItems="center"
                sx={{
                    height: '100vh',
                }}
            >
                <CircularProgress size={100} thickness={4} />
                <Typography sx={{ mt: '50px' }} variant="body1">
                    Please Wait a moment
                </Typography>
            </Stack>
        );
    }

    return (
        <CacheProvider value={siteLanguage.lang === 'ar' ? rtlCache : null}>
            <StyledEngineProvider injectFirst>
                <MyTheme>
                    <LocalizationProvider dateAdapter={DateAdapter}>
                        <Router>
                            <ScrollReset />
                            <ToastContainer
                                position="top-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                            {/* <GoogleAnalytics />
            <CookiesNotification /> */}
                            {!isLoggedIn ? (
                                <React.Fragment>
                                    <Redirect to="/login" />
                                    <Route path="/login" component={Auth} />
                                </React.Fragment>
                            ) : (
                                <ProtectedRoute path="/" component={MainRoutes} />
                            )}
                        </Router>
                    </LocalizationProvider>
                </MyTheme>
            </StyledEngineProvider>
        </CacheProvider>
    );
};

const App = () => {
    return (
        <ErrorBoundry>
            <StoreProvider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <StyledEngineProvider injectFirst>
                        <ConfigProvider />
                    </StyledEngineProvider>
                </PersistGate>
            </StoreProvider>
        </ErrorBoundry>
    );
};

export default App;
