import actionTypes from './actionTypes';

const {
  CLOSE_MODAL,
  OPEN_MODAL,
  SET_DEFAULT,
  CLOSE_MODAL_GALLERY,
  OPEN_MODAL_GALLERY,
  SET_DEFAULT_GALLERY,
  CHANGE_TAB,
  SET_GALLERY,
  SET_PREVIEW_IMAGE,
  UPDATE_VIDEO_LIST,
} = actionTypes;

const INITIAL_STATE = {
  isOpen: false,
  doUpdateVideoList: false,
  defaultValue: null,
  isOpenGallery: false,
  defaultValueGallery: null,
  galleryList: [],
  previewImage: null,
};

const uploadModalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLOSE_MODAL:
      return {
        ...state,
        isOpen: false,
        defaultValue: null,
      };
    case OPEN_MODAL:
      return {
        ...state,
        isOpen: true,
      };
    case SET_DEFAULT:
      return {
        ...state,
        defaultValue: action.payload,
      };
    case OPEN_MODAL_GALLERY:
      return {
        ...state,
        isOpenGallery: true,
      };
    case SET_GALLERY:
      return {
        ...state,
        galleryList: action.payload,
      };
    case CLOSE_MODAL_GALLERY:
      return {
        ...state,
        isOpenGallery: false,
        defaultValueGallery: null,
      };
    case SET_DEFAULT_GALLERY:
      return {
        ...state,
        defaultValueGallery: action.payload,
      };
    case CHANGE_TAB:
      return {
        ...state,
        galleryTab: action.payload,
      };
    case UPDATE_VIDEO_LIST:
      return {
        ...state,
        doUpdateVideoList: !state.doUpdateVideoList,
    };
      case SET_PREVIEW_IMAGE:
        return {
          ...state,
          previewImage: action.payload,
        }
    default:
      return state;
  }
};

export default uploadModalReducer;
