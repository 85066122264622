import React, { lazy, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useHistory } from 'react-router-dom';

import withDashboardLayout from 'layouts/Dashboard/Dashboard';
import { LinearProgress } from '@mui/material';
import { createRouteUrl } from './routeHelper';
import CheckRoutePermission from './CheckRoutePermission';

// Dashboard
const DashboardDefault = lazy(() => import('../views/DashboardDefault/DashboardDefault.js'));

// live
const Live = lazy(() =>
    import('../myPages/live/Live'),
);

const AddEditLive = lazy(() =>
    import('../myPages/live/AddEditLive'),
);

// Content
const ContentSeenLogReport = lazy(() =>
    import('../myPages/manage-content/content-library/Chart/ChartCustom'),
);

const ContentList = lazy(() =>
    import('../myPages/manage-content/content-library/content-list.component'),
);
const AddEditNewContentLayout = lazy(() =>
    import('../myPages/manage-content/addNewContent/AddEditNewContentLayout'),
);
const AddNewContent = lazy(() =>
    import('../myPages/manage-content/addNewContent/addContent.component'),
);
const AddEditNewSeason = lazy(() =>
    import('../myPages/manage-content/content-library/Season-Episode/SeasonPage'),
);

//Video
const VideoList = lazy(() =>
    import('../myPages/manage-content/video-library/videosList.component'),
);

// Comments
const CommentsList = lazy(() => import('../myPages/manage-content/comments/CommentsList.page'));
const ReportedCommentsList = lazy(() =>
    import('../myPages/manage-content/comments/ReportedCommentsList.page'),
);

//Gallery
const GalleryList = lazy(() => import('../myPages/Gallery/image&teasers/Gallery'));
const AvatarsList = lazy(() => import('../myPages/Gallery/Avatars/AvatarsList.page'));

// Cast&Crew
const CastAndCrewList = lazy(() =>
    import('../myPages/manage-content/cast&crew-library/CastAndCrewList'),
);
const AddNewCastCrew = lazy(() =>
    import('../myPages/manage-content/cast&crew-library/AddNewCastCrewPage'),
);
const CastAndCrewRolesList = lazy(() =>
    import('../myPages/manage-content/cast&crewRole/CastCrewRoles'),
);

//Monetization
const PaymentGateWay = lazy(() => import('../myPages/monetization/paymentGateWay/paymentGateway'));
const SubscriptionList = lazy(() => import('../myPages/monetization/Subscriptions/Subscriptions'));
const AddEditNewPlan = lazy(() => import('../myPages/monetization/Subscriptions/AddEditNewPlan'));
const AdsList = lazy(() => import('../myPages/monetization/ADS/AdsListPage'));
const AddEditNewAds = lazy(() => import('../myPages/monetization/ADS/AddEditNewAds.jsx'));
const SubscribersList = lazy(() => import('../myPages/monetization/Subscribers/SubscribersList'));
const CouponsList = lazy(() => import('../myPages/monetization/CVG/coupon/CouponsList'));
const AddEditCoupon = lazy(() => import('../myPages/monetization/CVG/coupon/AddEditCoupon'));
const VouchersList = lazy(() => import('../myPages/monetization/CVG/voucher/VouchersList'));
const addEditNewVoucher = lazy(() => import('../myPages/monetization/CVG/voucher/AddEditNewVoucher'));
const giftsList = lazy(() => import('../myPages/monetization/CVG/gift/GiftsList'));
const addEditNewGift = lazy(() => import('../myPages/monetization/CVG/gift/AddEditNewGift'));

//Pages (PageBuilder)
const MainPageBuilder = lazy(() => import('../myPages/Page-builders/MainPage/MainPageBuilder'));
const MainPageLanguage = lazy(() =>
    import('../myPages/Page-builders/MainPage/Languages/LanguagesList'),
);

//Meta-Data
const VideoCategoryGenre = lazy(() =>
    import('../myPages/manage-content/meta-data/VideoCategoryGenre/VideoCategoryGenre'),
);
const AddEditNewVideoCategory = lazy(() =>
    import('../myPages/manage-content/meta-data/VideoCategoryGenre/AddEditNewVideoCategory'),
);
const CategoryOfContents = lazy(() =>
    import(
        '../myPages/manage-content/meta-data/VideoCategoryGenre/Category Contents/ContentsOfCategory.page'
    ),
);

// Users
const UsersList = lazy(() => import('myPages/Users/UsersList.page'));

//Admins
const AdminsList = lazy(() => import('myPages/Admins/Admins Management/AdminsList.page'));
const PermissionsList = lazy(() =>
    import('myPages/Admins/permissions/permission-groups-management/PermissionList.page'),
);
const StaticPermissionsList = lazy(() =>
    import('myPages/Admins/permissions/static-permissions/PermissionsPage'),
);
const AddEditAdmin = lazy(() => import('myPages/Admins/Admins Management/AddEditAdmin.page'));

//Analaytics
const UsersAnalaytics = lazy(() => import('myPages/analaytics/users/UsersAnalaytic.page'));

// Notifications
const NotificationsList = lazy(() =>
    import('myPages/Notifications/List-of-Notifications/NotificationsList'),
);

const AddEditNotification = lazy(() =>
    import('myPages/Notifications/add-edit-notification/AddEditNotification'),
);

// Settings
const LanguagesListPage = lazy(() => import('myPages/site-settings/Languages/LanguagesList.page'));
const AgeCategories = lazy(() => import('myPages/site-settings/AgeCategories/AgeCategories.page'));
const SignInOptions = lazy(() => import('myPages/site-settings/SignInOptions/SignInOptions.page'));
const CompaniesListPage = lazy(() => import('myPages/site-settings/Companies/CompaniesList.page'));
const SiteSetting = lazy(() => import('myPages/site-settings/site-setting/SiteSetting'));

//Errors
// const Error401 = lazy(() => import('../views/Error401'));
const Error404 = lazy(() => import('../views/Error404'));
// const Error500 = lazy(() => import('../views/Error500'));

const MainRoutes = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    //   useEffect(() => {
    //     return history.listen(location => {
    //       dispatch(getBasicNotifications());
    //     });
    //   }, [history]);

    return (
        <Suspense fallback={<LinearProgress />}>
            <Switch>
                {/*Dashboard */}
                <Route exact path={createRouteUrl('home')} component={DashboardDefault} />

                {/*live */}
                <Route exact path={"/live"} component={Live} />
                <Route exact path={createRouteUrl('addEditLive')} component={AddEditLive} />

                {/*Content-library */}
                <Route exact path={createRouteUrl('contentSeenLogReport')} component={ContentSeenLogReport} />

                <Route exact path={createRouteUrl('contentList')} component={ContentList} />
                <Route
                    exact
                    path={createRouteUrl('addEditNewContent')}
                    component={AddEditNewContentLayout}
                />
                <Route exact path={createRouteUrl('addEditSeason')} component={AddEditNewSeason} />

                {/*Video-library */}
                <Route exact path={createRouteUrl('videoList')} component={VideoList} />

                {/* Comments */}
                <Route exact path={createRouteUrl('commentsList')} component={CommentsList} />
                <Route
                    exact
                    path={createRouteUrl('reportedCommentsList')}
                    component={ReportedCommentsList}
                />

                {/*Gallery */}
                <Route exact path={createRouteUrl('galleryList')} component={GalleryList} />
                <Route exact path={createRouteUrl('avatarsList')} component={AvatarsList} />

                {/*Cast-And-Crew-Library */}
                <Route exact path={createRouteUrl('castAndCrewList')} component={CastAndCrewList} />
                <Route exact path={createRouteUrl('addEditNewCast')} component={AddNewCastCrew} />
                <Route
                    exact
                    path={createRouteUrl('castAndCrewRolesList')}
                    component={CastAndCrewRolesList}
                />

                {/*Payment Gateways */}
                <Route exact path={createRouteUrl('paymentGateway')} component={PaymentGateWay} />

                {/* PageBuilder */}
                <Route
                    exact
                    path={createRouteUrl('mainPageLanguage')}
                    component={MainPageLanguage}
                />
                <Route exact path={createRouteUrl('mainPageBuilder')} component={MainPageBuilder} />

                {/*Monetization */}
                <Route
                    exact
                    path={createRouteUrl('subscriptionList')}
                    component={SubscriptionList}
                />
                <Route exact path={createRouteUrl('addEditNewPlan')} component={AddEditNewPlan} />
                <Route exact path={createRouteUrl('adsList')} component={AdsList} />
                <Route exact path={createRouteUrl('addEditNewAds')} component={AddEditNewAds} />
                <Route exact path={createRouteUrl('subscribersList')} component={SubscribersList} />
                <Route exact path={createRouteUrl('couponsList')} component={CouponsList} />
                <Route exact path={createRouteUrl('addEditCoupon')} component={AddEditCoupon} />
                <Route exact path={createRouteUrl('vouchersList')} component={VouchersList} />
                <Route exact path={createRouteUrl('addEditNewVoucher')} component={addEditNewVoucher} />
                <Route exact path={createRouteUrl('giftsList')} component={giftsList} />
                <Route exact path={createRouteUrl('addEditNewGift')} component={addEditNewGift} />

                {/* Users */}
                <Route exact path={createRouteUrl('usersList')} component={UsersList} />

                {/* Admins */}
                <Route path={createRouteUrl('adminsList')} component={AdminsList} />
                <Route
                    exact
                    path={createRouteUrl('permissionGroupsList')}
                    component={PermissionsList}
                />
                <Route
                    exact
                    path={createRouteUrl('staticPermissionsList')}
                    component={StaticPermissionsList}
                />

                {/* MetaData */}
                <Route
                    exact
                    path={createRouteUrl('videoCategory')}
                    component={VideoCategoryGenre}
                />
                <Route exact path={createRouteUrl('videoGenre')} component={VideoCategoryGenre} />
                <Route
                    exact
                    path={createRouteUrl('addEditNewVideoCategory')}
                    component={AddEditNewVideoCategory}
                />
                <Route
                    exact
                    path={createRouteUrl('categoryOfContents')}
                    component={CategoryOfContents}
                />

                {/* Analaytics */}
                <Route exact path={createRouteUrl('usersAnalaytics')} component={UsersAnalaytics} />

                {/* Notifications */}
                <Route
                    exact
                    path={createRouteUrl('notificationsList')}
                    component={NotificationsList}
                />

                <Route
                    exact
                    path={createRouteUrl('addEditNotification')}
                    component={AddEditNotification}
                />

                {/* Settings */}
                <Route exact path={createRouteUrl('languages')} component={LanguagesListPage} />
                <Route exact path={createRouteUrl('ageCategories')} component={AgeCategories} />
                <Route exact path={createRouteUrl('oAuth')} component={SignInOptions} />
                <Route exact path={createRouteUrl('companies')} component={CompaniesListPage} />
                <Route exact path={createRouteUrl('siteSetting')} component={SiteSetting} />
                <Route path="*" component={Error404} />
            </Switch>
        </Suspense>
    );
};

export default withDashboardLayout(MainRoutes);
