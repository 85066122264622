import actionTypes from './actionTypes';

const {
    CLOSE_MODAL,
    OPEN_MODAL,
    SET_DEFAULT,
    OPEN_MODAL_GALLERY,
    CLOSE_MODAL_GALLERY,
    SET_DEFAULT_GALLERY,
    CHANGE_TAB,
    SET_GALLERY,
    SET_PREVIEW_IMAGE,
    UPDATE_VIDEO_LIST,
} = actionTypes;

export const closeModal = () => ({
    type: CLOSE_MODAL,
});

export const openModal = () => ({
    type: OPEN_MODAL,
});

export const setDefaultValue = data => ({
    type: SET_DEFAULT,
    payload: data,
});

export const openModalGallery = () => ({
    type: OPEN_MODAL_GALLERY,
});

export const closeModalGallery = () => ({
    type: CLOSE_MODAL_GALLERY,
});

export const setDefeaultValueGallery = data => ({
    type: SET_DEFAULT_GALLERY,
    payload: data,
});

export const changeTab = data => ({
    type: CHANGE_TAB,
    payload: data,
});

export const setGalleryList = data => ({
    type: SET_GALLERY,
    payload: data,
});

export const setPreviewImage = data => ({
    type: SET_PREVIEW_IMAGE,
    payload: data,
});

export const doUpdateVideoList_Action = () => ({
    type: UPDATE_VIDEO_LIST,
});
