import React, { Suspense, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { LinearProgress } from '@mui/material';
import { NavBar, TopBar, ChatBar } from './components';
import UploadVideoModal from 'myComponents/Modals/UploadVideoModal/UploadVideoModal';
import { useSelector } from 'react-redux';
import { closeModal } from 'Redux/uploadModal/action';
import UploadSubtitleAndDubbed from 'myComponents/Modals/UploadSubtitle&Dub/UploadSubtitleAndDubbed';
import ExistedAudioSubtitleModal from 'myComponents/Modals/UploadSubtitle&Dub/ExistedAudioSubtitleModal/ExistedAudioSubtitle.modal';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  topBar: {
    zIndex: 2,
    position: 'relative',
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  navBar: {
    zIndex: 3,
    width: 256,
    minWidth: 256,
    flex: '0 0 auto',
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto',
  },
}));

const withDashboardLayout = WrappedComponent => {
  const Dashboard = props => {
    /* ****************************************************** Redux ***************************************************** */
    const uploadVideoModal = useSelector(state => state.uploadModal); // States of Video Upload Modal
    const uploadAudioAndSubtitle = useSelector(state => state.uploadAudioAndSubtitleModal) // Upload Audio and Subtitle Modal

    const classes = useStyles();

    /* ***************************************************** States ***************************************************** */
    const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

    /* **************************************************** Functions *************************************************** */
    const handleNavBarMobileOpen = () => {
      setOpenNavBarMobile(true);
    };

    const handleNavBarMobileClose = () => {
      setOpenNavBarMobile(false);
    };

    return (
      <div className={classes.root}>
        <TopBar className={classes.topBar} onOpenNavBarMobile={handleNavBarMobileOpen} />
        <div className={classes.container}>
          <NavBar className={classes.navBar} onMobileClose={handleNavBarMobileClose} openMobile={openNavBarMobile} />
          <main className={classes.content} id='main'>
            <Suspense fallback={<LinearProgress />}>
              <WrappedComponent {...props} />
            </Suspense>
          </main>
        </div>
        {/* <ChatBar /> */}
        {uploadVideoModal.isOpen && <UploadVideoModal />}
        {uploadAudioAndSubtitle.isExistedAudioSubtitleModalOpen && <ExistedAudioSubtitleModal />}
        {uploadAudioAndSubtitle.isUploadModalOpen && <UploadSubtitleAndDubbed />}
      </div>
    );
  };
  return Dashboard;
};

withDashboardLayout.propTypes = {
  route: PropTypes.object,
};

export default withDashboardLayout;
