const truncateStringByWords = (str, n) => {
    //   return str.length > n ? str.substr(0, n - 1) + '&hellip;' : str;
    const wordsOfString = str.split(' ');
    if (wordsOfString.length > n) {
        return wordsOfString
            .slice(0, [n])
            .join(' ')
            .replace(wordsOfString[n - 1], '... ');
    } else {
        return wordsOfString.join(' ');
    }
};

export const truncateStringByLetter = (str, start = 0, end) => {
    return `${str.substring(start, end)} ...`;
};

export default truncateStringByWords;
