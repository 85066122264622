import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { Cancel, Info } from '@mui/icons-material';
import { t } from 'i18next';

const NoOptionFound = props => {
    const { conditionToShowNoOptionFound, autocompleteName } = props;
    if (conditionToShowNoOptionFound) {
        return (
            <Stack alignItems="center" direction="row">
                <Cancel color="error" sx={{ mr: 1 }} />
                {t('No')} {autocompleteName} {t('found')}
            </Stack>
        );
    } else {
        return (
            <Stack alignItems="center" direction="row">
                <Info color="primary" sx={{ mr: 1 }} />
                {`${t('insert some letters to search between')} ${autocompleteName}`}
            </Stack>
        );
    }
};

NoOptionFound.propTypes = {
    conditionToShowNoOptionFound: PropTypes.bool.isRequired,
    autocompleteName: PropTypes.string.isRequired,
};

export default NoOptionFound;
