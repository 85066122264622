import React, { Fragment, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { LinearProgress, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { Route, Switch } from 'react-router';
import { Topbar } from '../layouts/Auth/components';
import theme from 'theme'

const Login = lazy(() => import('../views/Login'));

const useStyles = makeStyles(theme => ({
  content: {
    height: '100%',
    paddingTop: 56,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
}));

const Auth = props => {
  const { route, path } = props;

  const classes = useStyles();

  return (
    <StyledEngineProvider injectFirst>
      <Fragment>
        <main className={classes.content}>
          <Switch>
            <Suspense fallback={<LinearProgress />}>
              <Route path="/login" exact component={Login} />
            </Suspense>
          </Switch>
        </main>
      </Fragment>
    </StyledEngineProvider>
  );
};

Auth.propTypes = {
  route: PropTypes.object,
};

export default Auth;
