import { DataService } from 'utils/DataService/axios';
import { toastError } from 'components/Toasts/Toasts';
import Axios from 'axios';

/* **************************************************** Languages *************************************************** */
export const GetLanguages = (filters = {}, cancelToken = null) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { forWebsite = null, isArchived = null } = filters

      let queries = '';

      if (typeof forWebsite === 'boolean' && forWebsite) {
        queries += `forWebsite=${forWebsite}&`;
      }

      if (typeof isArchived === 'boolean' && isArchived) {
        queries += `archived=${isArchived}&`;
      }
      const res = await DataService.get(`/metadata/language?${queries}`, cancelToken);
      const response = res.data;
      if (response.status === 200) {
        resolve(response.result);
      } else if (response.hasOwnProperty('error')) {
        response.map(err => toastError(err));
        reject(response);
      } else {
        reject(response);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const AddNewLanguage = (data, cancelToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await DataService.post(`/admin/metadata/language`, data, cancelToken);
      const response = res.data;
      if (response.status === 200) {
        resolve(response.result);
      } else if (response.hasOwnProperty('error')) {
        response.map(err => toastError(err));
        reject(response);
      } else {
        reject(response);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const EditLanguage = (id, data, cancelToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await DataService.put(`/admin/metadata/language/${id}`, data, cancelToken);
      const response = res.data;
      if (response.status === 200) {
        resolve(response.result);
      } else if (response.hasOwnProperty('error')) {
        response.map(err => toastError(err));
        reject(response);
      } else {
        reject(response);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const toggleArchiveLanguage = (id, archiveStatus) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res;
      if (archiveStatus) {
        res = await DataService.put(`/admin/metadata/restoreLanguage/${id}`);
      } else {
        res = await DataService.delete(`/admin/metadata/language/${id}`);
      }
      const response = res.data;
      if (response.status === 200) {
        resolve(response.result);
      } else if (response.hasOwnProperty('error')) {
        response.map(err => toastError(err));
        reject(response);
      } else {
        reject(response);
      }
    } catch (error) {
      reject(error);
    }
  });
};

/* **************************************************** Countries *************************************************** */

export const getCountries = cancelToken => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await DataService.get(`/metadata/country`, cancelToken);
      const response = res.data;
      if (response.status === 200) {
        resolve(response.result);
      } else if (response.hasOwnProperty('error')) {
        response.map(err => toastError(err));
        reject(response);
      } else {
        reject(response);
      }
    } catch (error) {
      if (!Axios.isCancel(error)) {
        reject(error);
      }
    }
  });
};

/* ************************************************** AgeCategories ************************************************* */
export const getAgeCategories = (filters = {}, cancelToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { isArchived = null, withTranslate = null } = filters

      let filterQuery = ''

      if (typeof isArchived === 'boolean') {
        filterQuery += `archived=${isArchived}&`
      }

      if (typeof withTranslate === 'boolean') {
        filterQuery += `withTranslate=${withTranslate}&`
      }

      const res = await DataService.get(`/admin/metadata/ageCategory?${filterQuery}`, cancelToken);
      const response = res.data;
      if (response.status === 200) {
        resolve(response);
      } else {
        reject(response);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const addEditNewAgeCategory = (id = 0, data, cancelToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res;
      if (id === 0) {
        // Means Add New Category (Post API Call)
        res = await DataService.post(`/admin/metadata/ageCategory`, data, cancelToken);
      } else {
        // Means Edit Category (Put API Call)
        res = await DataService.put(`/admin/metadata/ageCategory/${id}`, data, cancelToken)
      }
      const response = res;
      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const toggleArchiveAgeCategory = (id, archiveStatus) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res;
      if (archiveStatus) {
        res = await DataService.put(`/admin/metadata/ageCategory/restore/${id}`)
      } else {
        res = await DataService.delete(`/admin/metadata/ageCategory/${id}`)
      }
      const response = res;
      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const translateAgeCategory = (languageId, ageCategoryId, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await DataService.put(`/admin/metadata/ageCategoryTranslate?languageId=${languageId}&ageCategoryId=${ageCategoryId}`, data)
      const response = res;
      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response)
      }
    } catch (err) {
      reject(err)
    }
  })
}