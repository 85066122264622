import React from 'react'
import PropTypes from 'prop-types'
import AntModal from '../Modal'
import { Stack, Typography } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'

const MakeSureClose = props => {
  const { onOk, onCancel } = props
  return (
    <AntModal
      visible
      onOk={onOk}
      onCancel={onCancel}
      onOkText='Cancel And Close Modal'
      okButtonProps={{
        color: 'error'
      }}
      cancelButtonProps={{
        variant: 'contained'
      }}
      title={
        <Stack direction="row" alignItems="center">
          <InfoOutlined color="error" fontSize="medium" sx={{ marginRight: 1 }} />
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Cancel Upload
          </Typography>
        </Stack>
      }
    >
      if You Close this Modal, the Upload Will Canceled! <br /> Are you Sure About it ?
    </AntModal>
  )
}

MakeSureClose.propTypes = {}

export default MakeSureClose