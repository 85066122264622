/* eslint-disable react/display-name */
import React, { useState, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { ListItem, Button, Collapse, colors } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import NavigationListItemStyle from './NavigationListItem.style';
import { useSelector } from 'react-redux';

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
        <RouterLink {...props} />
    </div>
));

const NavigationListItem = props => {
    const { title, href, depth, children, icon: Icon, className, open: openProp, label: Label, ...rest } = props;

    const { siteLanguage } = useSelector(state => state.generalData);
    const classes = NavigationListItemStyle();
    const [open, setOpen] = useState(openProp);

    const handleToggle = () => {
        setOpen(open => !open);
    };

    let valueOfPadding = 8;

    if (depth > 0) {
        valueOfPadding = 32 + 8 * depth;
    }

    let style = {};

    if (siteLanguage.dir === 'rtl') style.paddingRight = valueOfPadding;
    else style.paddingLeft = valueOfPadding;

    if (children) {
        return (
            <ListItem {...rest} className={clsx(classes.item, className)} disableGutters>
                <Button className={classes.button} onClick={handleToggle} style={style}>
                    {Icon && <Icon className={classes.icon} />}
                    {title}
                    {open ? (
                        <ExpandLessIcon className={classes.expandIcon} color="inherit" />
                    ) : (
                        <ExpandMoreIcon className={classes.expandIcon} color="inherit" />
                    )}
                </Button>
                <Collapse in={open}>{children}</Collapse>
            </ListItem>
        );
    } else {
        return (
            <ListItem {...rest} className={clsx(classes.itemLeaf, className)} disableGutters>
                <Button
                    activeClassName={classes.active}
                    className={clsx(classes.buttonLeaf, `depth-${depth}`)}
                    component={CustomRouterLink}
                    exact
                    style={style}
                    to={href}
                >
                    {Icon && <Icon className={classes.icon} />}
                    {title}
                    {Label && (
                        <span className={classes.label}>
                            <Label />
                        </span>
                    )}
                </Button>
            </ListItem>
        );
    }
};

NavigationListItem.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    depth: PropTypes.number.isRequired,
    href: PropTypes.string,
    icon: PropTypes.any,
    label: PropTypes.any,
    open: PropTypes.bool,
    title: PropTypes.string.isRequired,
};

NavigationListItem.defaultProps = {
    depth: 0,
    open: false,
};

export default NavigationListItem;
