/**
 * Random Component
 * @augments {Component<Props, State>}
 */

import React from 'react';
import {
    Box,
    CircularProgress,
    createTheme,
    IconButton,
    ThemeProvider,
    StyledEngineProvider,
    Stack,
} from '@mui/material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
} from '@mui/material';
import Transition from 'myComponents/Transtion/Transition';
import PropTypes, { oneOf, oneOfType } from 'prop-types';
import Draggable from 'react-draggable';
import Close from '@mui/icons-material/Close';
import { Delete } from '@mui/icons-material';
import '../../assets/scss/scrollbar.scss';

const PaperComponent = props => {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
};

export default class AntModal extends React.Component {
    render() {
        const {
            visible,
            title,
            onOk,
            onCancel,
            okButtonProps,
            cancelButtonProps,
            noAction,
            onOkText,
            onCancelText,
            children,
            scroll,
            fullWidth,
            maxWidth,
            hideBackDrop,
            onBackdropClick,
            isDraggable,
            uploadModal,
            closeIcon,
            titleFlexDirection,
            loading,
            onClickRemove,
            removeButton,
            removeText,
            removeColor,
            noRemoveIcon,
            removeLoading,
            removeButtonProps,
            fullScreen,
            additionalActionOptions,
        } = this.props;

        const theme = createTheme({
            components: {
                MuiDialog: {
                    styleOverrides: {
                        root: {
                            position: isDraggable ? 'absolute' : 'fixed',
                            width: isDraggable ? 'fit-content' : 'unset',
                            height: isDraggable ? 'fit-content' : 'unset',
                            top: isDraggable ? 'unset' : '0',
                            left: isDraggable ? 'unset' : '0',
                        },
                        paper: { width: isDraggable ? 300 : 600 },
                    },
                },
            },
        });

        const defaultTheme = createTheme({
            components: {
                MuiDialog: {
                    styleOverrides: {
                        root: {
                            position: 'fixed',
                        },
                    },
                },
            },
        });

        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={uploadModal ? theme : defaultTheme}>
                    <Dialog
                        open={visible}
                        onClose={onCancel}
                        TransitionComponent={Transition}
                        PaperComponent={isDraggable ? PaperComponent : null}
                        fullWidth={fullWidth}
                        maxWidth={maxWidth}
                        hideBackdrop={hideBackDrop}
                        onBackdropClick={onBackdropClick}
                        aria-labelledby={
                            isDraggable ? 'draggable-dialog-title' : 'alert-dialog-title'
                        }
                        aria-describedby="alert-dialog-description"
                        scroll={scroll}
                        fullScreen={fullScreen}
                    >
                        {title && (
                            <DialogTitle
                                id={isDraggable ? 'draggable-dialog-title' : 'alert-dialog-title'}
                                sx={{
                                    cursor: isDraggable ? 'move' : 'auto',
                                    display: 'flex',
                                    flexDirection: titleFlexDirection,
                                }}
                            >
                                {title}
                                {closeIcon && (
                                    <Box
                                        component="span"
                                        sx={{ flexGrow: 1, display: 'flex', justifyContent: 'end' }}
                                    >
                                        <IconButton onClick={onCancel} size="large">
                                            <Close />
                                        </IconButton>
                                    </Box>
                                )}
                            </DialogTitle>
                        )}
                        <DialogContent
                            sx={{ height: this.props.height, padding: this.props.padding }}
                            className="custom-scroll"
                        >
                            <DialogContentText
                                component="div"
                                id={
                                    isDraggable
                                        ? 'draggable-dialog-description'
                                        : 'alert-dialog-description'
                                }
                                sx={{ ...this.props.contentTextSX }}
                            >
                                {children}
                            </DialogContentText>
                        </DialogContent>
                        {!noAction && (
                            <DialogActions sx={{ display: 'block' }}>
                                <Stack direction="row-reverse" justifyContent="space-between">
                                    <Box>
                                        <Button
                                            onClick={onCancel}
                                            sx={{ marginRight: 1 }}
                                            {...cancelButtonProps}
                                        >
                                            {onCancelText}
                                        </Button>
                                        {additionalActionOptions}
                                        <Button
                                            onClick={onOk}
                                            disabled={loading}
                                            endIcon={
                                                loading ? (
                                                    <CircularProgress color="inherit" size={20} />
                                                ) : null
                                            }
                                            {...okButtonProps}
                                        >
                                            {onOkText}
                                        </Button>
                                    </Box>
                                    {removeButton && (
                                        <Box>
                                            <Button
                                                variant="contained"
                                                color={removeColor ?? 'error'}
                                                endIcon={
                                                    removeLoading ? (
                                                        <CircularProgress
                                                            color="inherit"
                                                            size={20}
                                                        />
                                                    ) : noRemoveIcon ? null : (
                                                        <Delete />
                                                    )
                                                }
                                                onClick={onClickRemove}
                                                disabled={removeLoading}
                                                {...removeButtonProps}
                                            >
                                                {removeText}
                                            </Button>
                                        </Box>
                                    )}
                                </Stack>
                            </DialogActions>
                        )}
                    </Dialog>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }
}

AntModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    onOkText: PropTypes.string,
    onCancelText: PropTypes.string,
    okButtonProps: PropTypes.object,
    cancelButtonProps: PropTypes.object,
    noAction: PropTypes.bool,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,
    hideBackDrop: PropTypes.bool,
    onBackdropClick: PropTypes.func,
    isDraggable: PropTypes.bool,
    uploadModal: PropTypes.bool,
    closeIcon: PropTypes.bool,
    titleFlexDirection: PropTypes.oneOf(['row', 'column', 'row-reverse', 'column-reverse']),
    loading: PropTypes.bool,
    onClickRemove: PropTypes.func,
    removeButton: PropTypes.bool,
    removeText: PropTypes.string,
    removeColor: PropTypes.string,
    noRemoveIcon: PropTypes.bool,
    removeButtonProps: PropTypes.object,
    removeLoading: PropTypes.bool,
    fullScreen: PropTypes.bool,
    additionalActionOptions: PropTypes.element,
};

AntModal.defaultProps = {
    scroll: 'paper',
    onOkText: 'Submit',
    onCancelText: 'Cancel',
    cancelButtonProps: {
        variant: 'outlined',
        color: 'primary',
    },
    okButtonProps: {
        variant: 'contained',
        color: 'primary',
    },
    titleFlexDirection: 'row',
    loading: false,
    removeLoading: false,
};
