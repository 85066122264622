import React from 'react';
import PropTypes from 'prop-types';
import { Box, Popover, Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setSiteLanguage } from 'Redux/GeneralData/actions';
import { changeLanguage } from 'i18next';

const LangBox = props => {
    const { onClick, langDetail } = props;
    return (
        <Stack
            direction={'row'}
            sx={{
                padding: '10px 5px',
                cursor: 'pointer',
                transition: 'ease all .3s',
                ':hover': {
                    color: 'red',
                },
            }}
            onClick={() => onClick(langDetail)}
        >
            <Typography fontWeight={'bold'} variant="body2" color="inherit">
                {langDetail.enName}
            </Typography>
        </Stack>
    );
};

LangBox.propTypes = {
    langDetail: PropTypes.object,
    onClick: PropTypes.func,
};

const LanguagePopover = props => {
    const { anchorEl, ...rest } = props;

    const dispatch = useDispatch();

    const handleChangeLang = value => {
        dispatch(setSiteLanguage(value));
        changeLanguage(value.lang);
        rest.onClose();
    };

    return (
        <Popover
            {...rest}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                // horizontal: 'center',
            }}
        >
            <Box
                sx={{
                    px: 2,
                    py: 1,
                }}
            >
                <Stack direction={'column'}>
                    <LangBox
                        langDetail={{ lang: 'en', dir: 'ltr', enName: 'English' }}
                        onClick={handleChangeLang}
                    />
                    <LangBox
                        langDetail={{ lang: 'ar', dir: 'rtl', enName: 'Arabic' }}
                        onClick={handleChangeLang}
                    />
                </Stack>
            </Box>
        </Popover>
    );
};

LanguagePopover.propTypes = {
    anchorEl: PropTypes.any,
};

export default LanguagePopover;
