import React, { Component } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';

class ErrorBoundry extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log({ error, errorInfo });
        this.setState(prev => ({
            ...prev,
            error,
            errorInfo,
        }));
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <Box
                    sx={{
                        width: '100vw',
                        height: '100vh',
                        textAlign: 'center',
                        backgroundColor: 'aquamarine',
                    }}
                >
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        flexGrow={1}
                        sx={{ height: '100%' }}
                    >
                        <Typography variant="h1" fontWeight="bold">
                            Something Went Wrong
                        </Typography>
                    </Stack>
                    <Stack direction={'column'}>
                        <Box>
                            <span>
                                <Typography fontWeight={'bold'}>
                                    Error : {this.state?.error}
                                </Typography>
                            </span>
                        </Box>
                        <Box>
                            <span>
                                <Typography fontWeight={'bold'}>
                                    Error Info: {this.state?.errorInfo}
                                </Typography>
                            </span>
                        </Box>
                    </Stack>
                </Box>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundry;
