import React from 'react';
import AntModal from 'myComponents/Modals/Modal';
import { useDispatch, useSelector } from 'react-redux';
import {
    closeExistedAudioSubtitleModal,
    openUploadAudioAndSubModal,
} from 'Redux/UploadAudioAndSubtitle/actions';
import { Button, Chip, Grid, IconButton, Paper, Stack, Typography } from '@mui/material';
import CustomTooltip from 'myComponents/Tooltip/CustomTooltip';
import { Edit } from '@mui/icons-material';
import { truncateStringByLetter } from 'utils/truncateString';

const NoData = props => {
    const { onClick } = props;
    return (
        <Grid
            item
            xs={12}
            sx={{ textAlign: 'center', minHeight: 400 }}
            alignItems="center"
            justifyContent="center"
        >
            <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                    height: '100%',
                    width: '25%',
                    mx: 'auto',
                }}
            >
                <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{
                        mb: 1,
                    }}
                >
                    No Data Found
                </Typography>
                <Button variant="contained" onClick={onClick}>
                    Add New File
                </Button>
            </Stack>
        </Grid>
    );
};

const ExistedAudioSubtitleModal = () => {
    /* ************************************************ Redux And Statics *********************************************** */
    const modalData = useSelector(state => state.uploadAudioAndSubtitleModal);
    const dispatch = useDispatch();
    const isAudioModal = modalData.isAudio ? 'Audio ( Dub )' : 'Subtitle';

    /* **************************************************** Functions *************************************************** */
    const handleCancel = () => {
        dispatch(closeExistedAudioSubtitleModal());
    };

    const openUploadModal = () => {
        dispatch(openUploadAudioAndSubModal());
    };

    const openUploadModalInEditMode = fileToEdit => {
        dispatch(openUploadAudioAndSubModal(fileToEdit));
    };

    return (
        <AntModal
            visible
            fullWidth
            maxWidth="md"
            onCancel={handleCancel}
            okButtonProps={{
                sx: {
                    display: 'none',
                },
            }}
            cancelButtonProps={{
                color: 'error',
            }}
            title={
                <Stack direction="row" justifyContent="space-between" width="100%">
                    <Typography variant="h6" fontWeight="bold">
                        List of {isAudioModal}
                    </Typography>
                    <Button variant="contained" onClick={openUploadModal}>
                        Add new File
                    </Button>
                </Stack>
            }
        >
            <Grid
                container
                columns={13}
                columnGap={1}
                rowGap={2}
                sx={{ py: 1 }}
                justifyContent="center"
            >
                {!modalData?.existedLanguages?.length ? (
                    // if No data Exist
                    <NoData onClick={openUploadModal} />
                ) : (
                    modalData.existedLanguages.map(item => (
                        <Grid item key={item?.id} lg={4} sm={6} xs={12}>
                            <Paper elevation={2} sx={{ height: '100%' }}>
                                <Stack direction="row" width="100%" sx={{ p: 2 }}>
                                    <Stack direction="column" flexGrow={1} justifyContent="start">
                                        <Typography variant="subtitle2" sx={{ color: 'gray' }}>
                                            File name :
                                        </Typography>
                                        {item?.filename.length > 25
                                            ? truncateStringByLetter(item.filename, 0, 17)
                                            : item.filename}
                                        <Typography
                                            variant="subtitle2"
                                            sx={{ color: 'gray', mt: 2 }}
                                        >
                                            File Title :
                                        </Typography>
                                        {item?.title}
                                        <Typography
                                            variant="subtitle2"
                                            sx={{ color: 'gray', mt: 2 }}
                                        >
                                            File Language name :
                                        </Typography>
                                        {item?.language?.name ? (
                                            <Chip
                                                label={item.language.name}
                                                sx={{
                                                    width: 'fit-content',
                                                    fontWeight: 'bold',
                                                    px: 2,
                                                }}
                                            />
                                        ) : (
                                            <Typography variant="caption">NOT FOUND</Typography>
                                        )}
                                    </Stack>
                                    <Stack direction="column" justifyContent="start">
                                        <CustomTooltip title="Edit File">
                                            <Button
                                                onClick={() => openUploadModalInEditMode(item)}
                                                variant="contained"
                                                sx={{
                                                    borderRadius: '50%',
                                                    minWidth: 'fit-content',
                                                    p: '10px',
                                                }}
                                            >
                                                <Edit fontSize="0.99" />
                                            </Button>
                                        </CustomTooltip>
                                    </Stack>
                                </Stack>
                            </Paper>
                        </Grid>
                    ))
                )}
            </Grid>
        </AntModal>
    );
};

export default ExistedAudioSubtitleModal;
