import moment from 'moment';

const toSeconds = time => {
  // convert Moment Object to Seconds
  const timeString = time?._i;
  if (timeString.includes(':')) {
    const timeStringSplit = timeString.split(':');
    let formatTime = {
      seconds: Boolean(timeStringSplit[2]) ? timeStringSplit[2] : '00',
      minutes: Boolean(timeStringSplit[1]) ? timeStringSplit[1] : '00',
      hours: timeStringSplit[0],
    };
    return moment.duration(formatTime).asSeconds();
  }
};

export default toSeconds;
