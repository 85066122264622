const getTusUploadId = file => {
    let uploadUrl = '';
    if (file.successful) {
        uploadUrl = file.successful[0].uploadURL.split('/');
    } else {
        uploadUrl = file.uploadURL.split('/');
    }
    const fileTusId = uploadUrl[uploadUrl.length - 1];
    return fileTusId;
};

export default getTusUploadId;
