import React, { useEffect, useState } from 'react';
import { Slide } from '@mui/material';

const Transition = React.forwardRef((props, ref) => {
  return (
    <Slide direction={props.direction} ref={ref} {...props} mountOnEnter unmountOnExit>
      {props.children}
    </Slide>
  );
});

Transition.defaultProps = {
  direction: 'down',
};

export default Transition;
