import { toast } from 'react-toastify';

import alarmSuccess from '../../assets/audio/messenger-notification.wav';
import warningAlarm from '../../assets/audio/clunk-notification-alert_D_major.wav';

const successSound = () => {
    const audio = new Audio(alarmSuccess);
    audio.play();
};
const warningSound = () => {
    const audio = new Audio(warningAlarm);
    audio.play();
    audio.volume = 0.1;
};

export const toastError = (message, theme = 'light') => {
    toast.error(message, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme,
    });
    warningSound();
};

export const toastSuccess = (message, theme = 'light') => {
    toast.success(message, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme,
    });
    successSound();
};

export const toastWarning = (message, theme = 'light') => {
    toast.warning(message, {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme,
    });
    warningSound();
};
