import { setVODConfiguration } from 'Redux/GeneralData/actions';
import { GetVODConfiguration_API } from 'api/Settings/Configuration.api';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const ProtectedRoute = ({ component, path }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const getConfiguration = () => {
        GetVODConfiguration_API().then(res => {
            dispatch(setVODConfiguration(res))
        }).catch(err => { })
    }

    useEffect(() => {
        if (window.location.pathname === '/login') {
            history.push('/');
        }
        else {
            getConfiguration()
        }
    }, []);

    return <Route exact={false} component={component} path={path} />;
};

export default ProtectedRoute;
