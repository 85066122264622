import React, { useState, useEffect } from 'react';
import { Autocomplete, Chip, TextField, Typography } from '@mui/material';
import { GetLanguages } from 'api/GeneralData/GeneralDataApi';
import Axios from 'axios';
import RequiredInput from 'myComponents/Required/RequiredInput';
import PropTypes from 'prop-types';
import { t } from 'i18next';

const SearchLanguages = React.forwardRef((props, ref) => {
    const {
        onChange,
        multiple,
        helpertext,
        error,
        label,
        disabled,
        forWebsite,
        required,
        ...rest
    } = props;

    const [languages, setLanguages] = useState([]);

    // if (rest.value?.length && Array.isArray(rest.value)) {
    //   rest.value = languages.filter((item) => rest.value.includes(item.code));
    // }

    useEffect(() => {
        const cancelRequsetHandler = Axios.CancelToken.source();

        if (!languages.length) {
            GetLanguages({ forWebsite }, cancelRequsetHandler.token)
                .then(res => {
                    setLanguages(res);
                })
                .catch(err => err);
        }
        return () => {
            cancelRequsetHandler.cancel();
        };
    }, []);

    return (
        <Autocomplete
            {...rest}
            ref={ref}
            filterSelectedOptions
            multiple={multiple}
            options={languages}
            onChange={onChange}
            disabled={disabled}
            id="search-languages"
            // renderTags={(option) => <>{option.name}</>}
            getOptionLabel={option => option.name ?? ''}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderInput={params => (
                <TextField
                    {...params}
                    error={error}
                    disabled={disabled}
                    margin="dense"
                    label={t(label)}
                    placeholder={t('Search for Language')}
                    helperText={helpertext}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {required && <RequiredInput />}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
});

SearchLanguages.propTypes = {
    onChange: PropTypes.func,
    multiple: PropTypes.bool,
    helpertext: PropTypes.string,
    error: PropTypes.bool,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    forWebsite: PropTypes.bool,
    required: PropTypes.bool,
};

SearchLanguages.defaultProps = {
    forWebsite: null,
};

export default SearchLanguages;
