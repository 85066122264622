import * as LocalStorage from '../../utils/localStorage';
import actionTypes from './actionTypes';
import {
    CheckIsLoggdIn,
    EmailCheck,
    LoginByPassword,
    logout,
    OTPCheck,
    PasswordCheck,
} from 'api/Login/LoginApi';
import { store } from 'Redux/store';
import { createUrl } from 'routes/routeHelper';

/************************************Login With Password***************************/
export const LoginByPasswordStart = () => ({
    type: actionTypes.loginWithPasswordStart,
});

export const LoginByPasswordComplete = data => ({
    type: actionTypes.loginWithPasswordComplete,
    payload: data,
});

export const LoginByPasswordFailed = err => ({
    type: actionTypes.loginWithPasswordFailed,
    payload: err,
});

export const LoginByPasswordAsync = (email, password) => {
    return async dispatch => {
        try {
            dispatch(LoginByPasswordStart());
            const res = await LoginByPassword(email, password);
            if (res.status === 200) {
                const result = res.result;
                LocalStorage.setItem('ACCESS_TOKEN', result?.accessToken);
                // *******************
                //TODO: change the user.email to user.name
                LocalStorage.setItem('USER_NAME', result.user.email);
                // *********************
                return dispatch(LoginByPasswordComplete(result));
            } else {
                return dispatch(LoginByPasswordFailed(res.message));
            }
        } catch (error) {
            return dispatch(LoginByPasswordFailed(error));
        }
    };
};

/************************************Set Email For OTP***************************/

export const setEmailForOTP = email => ({
    type: actionTypes.setEmailForOTP,
    payload: email,
});

/************************************Login With OTP***************************/
export const otpCheckStart = () => ({
    type: actionTypes.otpCheckStart,
});

export const otpCheckComplete = data => ({
    type: actionTypes.otpCheckComplete,
    payload: data,
});

export const otpCheckFailed = error => ({
    type: actionTypes.otpCheckFailed,
    payload: error,
});

export const otpCheckAsync = OTPCode => {
    return async dispatch => {
        try {
            dispatch(otpCheckStart());
            let storeState = store.getState();
            let email = storeState.auth?.emailForOTP;
            const res = await OTPCheck(email, OTPCode);
            if (res.status === 200) {
                const result = res.result;
                LocalStorage.setItem('ACCESS_TOKEN', result?.AccessToken);
                // *******************
                // it's Temporaily
                LocalStorage.setItem('USER_NAME', result.user.username);
                // *********************
                return dispatch(otpCheckComplete(res));
            } else {
                return dispatch(otpCheckFailed(res));
            }
        } catch (error) {
            return dispatch(otpCheckFailed(error));
        }
    };
};

/************************************Check is User Logged in***************************/
export const checkIsLoggedInStart = () => ({
    type: actionTypes.checkIsLoggedInStart,
});

export const setWebsiteURL = result => ({
    type: actionTypes.SET_WEBSITE_URL,
    payload: result,
});

export const checkIsLoggedInComplete = result => ({
    type: actionTypes.checkIsLoggedInComplete,
    payload: result,
});

export const checkIsLoggedInFailed = () => ({
    type: actionTypes.checkIsLoggedInFailed,
});

export const checkIsLoggedInAsync = () => {
    return async dispatch => {
        // dispatch(checkIsLoggedInComplete());

        try {
            dispatch(checkIsLoggedInStart());
            if (LocalStorage.getItem('ACCESS_TOKEN')) {
                const res = await CheckIsLoggdIn();
                if (res.status === 200) {
                    dispatch(setWebsiteURL(res.data?.result?.websiteURL));
                    return dispatch(checkIsLoggedInComplete(res.data.result));
                } 
                else {
                    LocalStorage.removeItem('ACCESS_TOKEN');
                    LocalStorage.removeItem('USER_NAME');
                    return dispatch(checkIsLoggedInFailed());
                }
            } else {
                return dispatch(checkIsLoggedInFailed());
            }
        } catch (error) {
            return dispatch(checkIsLoggedInFailed());
        }
    };
};

/************************************Logout***************************/
export const logOutStart = () => ({
    type: actionTypes.logOutStart,
});

export const logOutComplete = data => ({
    type: actionTypes.logOutComplete,
    payload: data,
});

export const logOutFailed = error => ({
    type: actionTypes.logOutFailed,
    payload: error,
});

export const logOutAsync = () => {
    return async dispatch => {
        try {
            dispatch(logOutStart());
            const res = await logout();
            if (res.status === 200) {
                LocalStorage.removeItem('ACCESS_TOKEN');
                LocalStorage.removeItem('USER_NAME');
                return dispatch(logOutComplete(res));
            } else {
                return dispatch(logOutFailed(res));
            }
        } catch (error) {
            return dispatch(logOutFailed(error));
        }
    };
};

/************************************Check Email ( Not Used )***************************/
export const EmailCheckStart = email => ({
    type: actionTypes.emailCheckStart,
    payload: email,
});

export const EmailCheckComplete = data => ({
    type: actionTypes.emailCheckComplete,
    payload: data,
});

export const EmailCheckFailed = error => ({
    type: actionTypes.emailCheckFailed,
    payload: error,
});

export const EmailCheckAsync = email => {
    return async dispatch => {
        try {
            dispatch(EmailCheckStart());
            const res = await EmailCheck(email);
            if (res.status === 200) {
                return dispatch(EmailCheckComplete(email));
            } else {
                return dispatch(EmailCheckFailed(res));
            }
        } catch (err) {
            return dispatch(EmailCheckFailed(err));
        }
    };
};

/************************************Password Check (Not Used)***************************/
export const passwordCheckStart = () => ({
    type: actionTypes.passwordCheckStart,
});

export const passwordCheckComplete = data => ({
    type: actionTypes.passwordCheckComplete,
    payload: data,
});

export const passwordCheckFailed = error => ({
    type: actionTypes.passwordCheckFailed,
    payload: error,
});

export const passwordCheckAsync = password => {
    return async dispatch => {
        try {
            dispatch(passwordCheckStart());
            let storeState = store.getState();
            let email = storeState.auth?.email;
            const res = await PasswordCheck(email, password);
            if (res.status === 200) {
                return dispatch(passwordCheckComplete(password));
            } else {
                return dispatch(passwordCheckFailed(res));
            }
        } catch (error) {
            return dispatch(passwordCheckFailed(error));
        }
    };
};
