import actionTypes from './actionTypes';

const { GET_COUNTRIES, GET_LANGUAGES, GET_PERMISSION_GROUPS } = actionTypes;

const INITAIL_STATE = {
    countries: [],
    languages: [],
    permissionGroups: [],
};

const AutoCompleteReducer = (state = INITAIL_STATE, action) => {
    switch (action.type) {
        case GET_COUNTRIES:
            return {
                ...state,
                countries: action.payload,
            };
        case GET_LANGUAGES:
            return {
                ...state,
                languages: action.payload,
            };
        case GET_PERMISSION_GROUPS:
            return {
                ...state,
                permissionGroups: action.payload,
            };
        default:
            return state;
    }
};

export default AutoCompleteReducer;
