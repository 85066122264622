import { DataService } from 'utils/DataService/axios';
import { toastError, toastSuccess, toastWarning } from 'components/Toasts/Toasts';

export const GetContentList = (filters = {}, cancelToken = null) => {
    let filtersOnUrl = '';
    const {
        isDraft = null,
        title = null,
        permaLink = null,
        isParent = null,
        parentId = null,
        hasFile = null,
        streamCode = null,
        take = null,
        page = null,
        hasCategories = [],
        hasGenres = [],
        hasCountries = [],
        needCategory = null,
        needGenre = null,
        needCountries = null,
        languageId = null,
        minimumData = null,
        allTitle = null,
    } = filters;

    if (typeof isDraft === 'boolean') filtersOnUrl += `isDraft=${isDraft}&`;
    if (title && title?.length > 2) filtersOnUrl += `title=${title}&`;
    if (permaLink) filtersOnUrl += `permaLink=${permaLink}&`;
    if (typeof isParent === 'boolean') filtersOnUrl += `isParent=${isParent}&`;
    if (parentId && typeof parentId === 'number') filtersOnUrl += `parentId=${parentId}&`;
    if (hasFile) filtersOnUrl += `hasFile=${hasFile}&`;
    if (streamCode && typeof streamCode === 'string') filtersOnUrl += `streamCode=${streamCode}&`;
    if (take) filtersOnUrl += `take=${take}&`;
    if (page) filtersOnUrl += `page=${page}&`;
    if (hasCategories.length > 0) {
        filtersOnUrl += `hasCategories=${hasCategories.join(',')}&`;
    }
    if (hasGenres.length > 0) {
        filtersOnUrl += `hasGenres=${hasGenres.join(',')}&`;
    }
    if (hasCountries && hasCountries.length > 0) {
        filtersOnUrl += `hasCountries=${hasCountries.join(',')}&`;
    }
    if (needCategory) filtersOnUrl += `needCategory=${needCategory}&`;
    if (needGenre) filtersOnUrl += `needGenre=${needGenre}&`;
    if (needCountries) filtersOnUrl += `needCountries=${needCountries}&`;
    if (languageId && (typeof languageId === 'number' || typeof languageId === 'string'))
        filtersOnUrl += `languageId=${languageId}&`;
    if (minimumData) filtersOnUrl += `minimumData=${minimumData}&`;
    if (allTitle && allTitle?.length > 2) filtersOnUrl += `allTitle=${allTitle}&`;

    return new Promise(async (resolve, reject) => {
        try {
            const res = await DataService.get(`/admin/content?${filtersOnUrl}`, cancelToken);
            const response = res;
            if (response.status === 200) {
                resolve(response.data.result);
            } else if (response.hasOwnProperty('error')) {
                response.map(err => toastError(err));
                reject(response);
            } else {
                toastError('try again !');
                reject(response);
            }
        } catch (error) {
            reject(error);
        }
    });
};

export const getContentDetailById = id => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await DataService.get(`/admin/content/${id}`);
            const response = res;
            if (response.status === 200) {
                resolve(response.data.result);
            } else if (response.hasOwnProperty('error')) {
                response.map(err => toastError(err));
                reject(response);
            } else {
                toastError('try again !');
                reject(response);
            }
        } catch (error) {
            reject(error);
        }
    });
};

export const addEditNewContent = (data, type = 'add', id = null, cancelToken = null) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res;
            if (type === 'add') {
                res = await DataService.post(`/admin/content`, data);
            } else if (type === 'edit') {
                res = await DataService.put(`/admin/content/${id}`, data);
            }
            const response = res.data;
            if (response.status === 200) {
                toastSuccess(`Successfully ${type === 'add' ? 'Added' : 'Edited'}`);
                resolve(response);
            } else {
                reject(response);
            }
        } catch (err) {
            reject(err);
        }
    });
};

export const addEditRemoveGalleryOfContents = (videoContentId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await DataService.put(`/admin/content/gallery/${videoContentId}`, data);
            if (res.status === 200) {
                resolve(res.data);
            } else {
                toastError(res?.data?.message);
                reject(res);
            }
        } catch (error) {
            reject(error);
        }
    });
};

export const translateContent = (data, id = null) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await DataService.put(`/admin/content/translate/${id}`, data);
            const response = res.data;
            if (response.status === 200) {
                toastSuccess(`Successfully Translated`);
                resolve(response);
            } else if (response.hasOwnProperty('error')) {
                response.map(err => alert(err));
                reject(response);
            } else {
                toastError(response?.message);
                reject(response);
            }
        } catch (err) {
            reject(err);
        }
    });
};

export const removeContent = id => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await DataService.delete(`/admin/content/${id}`);
            const response = res.data;
            if (response.status === 200) {
                toastSuccess('Removed');
                resolve(response.result);
            } else if (response.hasOwnProperty('error')) {
                response.map(err => alert(err));
                reject(response);
            } else {
                toastError(response?.message);
                reject(response);
            }
        } catch (error) {
            reject(error);
        }
    });
};

export const commentsGetApiCall = (filters = {}, cancelToken = null) => {
    return new Promise(async (resolve, reject) => {
        try {
            const {
                confirmed = null,
                userId = '',
                isSpoil = '',
                sort = null,
                take = 10,
                page = 1,
                videoContentId = null,
            } = filters;
            let filterOnUrl = '';
            if (confirmed !== null && typeof confirmed === 'boolean')
                filterOnUrl += `confirmed=${confirmed}&`;
            if (userId.length !== 0 && userId) filterOnUrl += `userId=${userId}&`;
            if (typeof isSpoil === 'boolean') filterOnUrl += `isSpoil=${isSpoil}&`;
            if (sort) filterOnUrl += `sort=${sort}&`;
            if (take) filterOnUrl += `take=${take}&`;
            if (page) filterOnUrl += `page=${page}&`;
            if (videoContentId) filterOnUrl += `videoContentId=${videoContentId}&`;

            const res = await DataService.get(`/admin/comments?${filterOnUrl}`, cancelToken);
            const response = res.data;
            if (response.status === 200) {
                resolve(response.result);
            } else if (response.hasOwnProperty('error')) {
                response.map(err => toastError(err));
                reject(response);
            } else {
                reject(response);
            }
        } catch (error) {
            reject(error);
        }
    });
};

export const toggleCommentConfirmation = (commentId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await DataService.put(`/admin/comments/${commentId}`, data);
            const response = res.data;
            if (response.status === 200) {
                if (data.confirmed) {
                    toastSuccess('Comment confirmed');
                } else {
                    toastWarning('Comment rejected');
                }
                resolve(response);
            } else if (response.hasOwnProperty('error')) {
                response.map(err => toastError(err));
                reject(response);
            } else {
                toastError(response?.message);
                reject(response);
            }
        } catch (err) {
            reject(err);
        }
    });
};

export const commentReportsList = (filters = {}, cancelToken = null) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { commentId = null, sort = null } = filters;
            let filterOnUrl = '';
            if (commentId && Array.isArray(commentId)) filterOnUrl += `commentId=${commentId}&`;
            if (sort && typeof sort === 'boolean') filterOnUrl += `sort=${sort}&`;
            const res = await DataService.get(
                `/admin/comments/reports?${filterOnUrl}`,
                cancelToken,
            );
            const response = res.data;
            if (response.status === 200) {
                resolve(response.result);
            } else if (response.hasOwnProperty('error')) {
                response.map(err => toastError(err));
                reject(response);
            } else {
                reject(response);
            }
        } catch (error) {
            reject(error);
        }
    });
};

export const commentReportCheckedAsViewed = reportId => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await DataService.put(`/admin/comments/reports/${reportId}/review`, {
                //! the {isNew : false} means report checked as viewed and not new
                isNew: false,
            });
            const response = res.data;
            if (response.status === 200) {
                resolve(response);
            } else if (response.hasOwnProperty('error')) {
                response.map(err => toastError(err));
                reject(response);
            } else {
                toastError(response?.message);
                reject(response);
            }
        } catch (err) {
            reject(err);
        }
    });
};
