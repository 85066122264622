import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Stack } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { t } from 'i18next';

const AccordionCustom = props => {
    const { iconColor, grow, title, titleColor, justify, children, otherData, icon } = props;
    return (
        <Accordion
            sx={[
                { width: '100%', boxShadow: 'none', justifyContent: justify ?? 'start' },
                {
                    '&:before': {
                        opacity: '0',
                    },
                },
            ]}
            style={{ marginTop: 0 }}
        >
            <AccordionSummary
                expandIcon={icon ?? <ExpandMore color={iconColor} />}
                sx={[
                    { justifyContent: justify ?? 'start' },
                    { '.MuiAccordionSummary-content': { flexGrow: grow ? 0 : 1 } },
                ]}
            >
                <Stack direction="row" alignItems="center">
                    <Typography color={titleColor} sx={{ mr: 1 }}>
                        {t(title)}
                    </Typography>
                    {otherData}
                </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>{children}</AccordionDetails>
        </Accordion>
    );
};

AccordionCustom.propTypes = {
    iconColor: PropTypes.string,
    icon: PropTypes.element,
    grow: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    title: PropTypes.string,
    titleColor: PropTypes.string,
    justify: PropTypes.oneOf(['start', 'end', 'center']),
};

export default AccordionCustom;
