const actionTypes = {
  setEmailForOTP: 'SET_EMAIL_FOR_OTP',
  loginWithPasswordStart: 'LOGIN_WITH_PASSWORD_START',
  loginWithPasswordComplete: 'LOGIN_WITH_PASSWORD_COMPELETE',
  loginWithPasswordFailed: 'LOGIN_WITH_PASSWORD_FAILED',
  passwordCheckStart: 'PASSWORD_CHECK_START',
  passwordCheckComplete: 'PASSWORD_CHECK_COMPLETE',
  passwordCheckFailed: 'PASSWORD_CHECK_FAILED',
  otpCheckStart: 'OTP_CHECK_START',
  otpCheckComplete: 'OTP_CHECK_COMPELETE',
  otpCheckFailed: 'OTP_CHECK_FAILED',
  emailCheckStart: 'EMAIL_CHECK_START',
  emailCheckFailed: 'EMAIL_CHECK_FAILED',
  emailCheckComplete: 'EMAIL_CHECK_COMPLETE',
  logOutStart: 'LOGOUT_START',
  logOutComplete: 'LOGOUT_COMPLETE',
  logOutFailed: 'LOGOUT_FAIELD',
  checkIsLoggedInStart: 'CHECK_IS_LOGGED_IN_START',
  checkIsLoggedInComplete: 'CHECK_IS_LOGGED_IN_COMPLETE',
  checkIsLoggedInFailed: 'CHECK_IS_LOGGED_IN_FAILED',
  SET_WEBSITE_URL: 'SET_WEBSITE_URL',
};

export default actionTypes;
