import Cookies from 'js-cookie';
import actionTypes from './actionTypes';

const {
    setEmailForOTP,
    loginWithPasswordStart,
    loginWithPasswordFailed,
    loginWithPasswordComplete,
    otpCheckComplete,
    otpCheckFailed,
    otpCheckStart,
    passwordCheckComplete,
    passwordCheckFailed,
    passwordCheckStart,
    emailCheckComplete,
    emailCheckFailed,
    emailCheckStart,
    logOutStart,
    logOutComplete,
    logOutFailed,
    checkIsLoggedInStart,
    checkIsLoggedInComplete,
    SET_WEBSITE_URL,
    checkIsLoggedInFailed,
} = actionTypes;

const INITAL_STATE = {
    isLoggedIn: null,
    userName: null,
    loading: false,
    emailForOTP: null,
    response: null,
    error: null,
    websiteURL: ""
};

const AuthCheckReducer = (state = INITAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_WEBSITE_URL:
            return {
                ...state,
                websiteURL: payload,
            };
        case loginWithPasswordStart:
            return {
                ...state,
                loading: true,
            };
        case loginWithPasswordComplete:
            return {
                ...state,
                response: payload,
                loading: false,
                isLoggedIn: true,
                userName: payload.user.email,
            };
        case loginWithPasswordFailed:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case setEmailForOTP:
            return {
                ...state,
                emailForOTP: payload,
            };
        case emailCheckStart:
            return {
                ...state,
                loading: true,
                email: payload,
            };
        case emailCheckComplete:
            return {
                ...state,
                loading: false,
                email: payload,
            };
        case emailCheckFailed:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case otpCheckStart:
            return {
                ...state,
                loading: true,
            };
        case otpCheckComplete:
            return {
                ...state,
                response: payload,
                otp: payload,
                loading: false,
                isLoggedIn: true,
            };
        case otpCheckFailed:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case passwordCheckStart:
            return {
                ...state,
                loading: true,
            };
        case passwordCheckComplete:
            return {
                ...state,
                password: payload,
                loading: false,
                isLoggedIn: true,
            };
        case passwordCheckFailed:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case logOutStart:
            return {
                ...state,
                loading: true,
            };
        case logOutComplete:
            return {
                ...state,
                email: null,
                password: null,
                otp: null,
                isLoggedIn: false,
                loading: false,
                response: payload,
            };
        case logOutFailed:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case checkIsLoggedInStart:
            return {
                ...state,
                loading: true,
            };
        case checkIsLoggedInComplete:
            return {
                ...state,
                response: payload,
                loading: false,
                isLoggedIn: true,
            };
        case checkIsLoggedInFailed:
            return {
                ...state,
                loading: false,
                isLoggedIn: false,
            };
        default:
            return state;
    }
};

export default AuthCheckReducer;
