import { MockDataService } from 'utils/DataService/mockDataAxios';
import { DataService } from 'utils/DataService/axios';
import { toastError, toastSuccess } from 'components/Toasts/Toasts';
import Axios from 'axios';

export const SearchRelatedContentAsync = (searchText, cancelToken = null) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await DataService.get(
        `/admin/content?title=${searchText}&minimumData=true`,
        cancelToken,
      );
      const response = res.data;
      if (response.status === 200) {
        resolve(response.result);
      } else if (response.hasOwnProperty('error')) {
        reject(response);
      } else {
        reject(response);
      }
    } catch (error) {
      if (!Axios.isCancel(error)) {
        reject(error);
      }
    }
  });
};

export const SearchTagsAsync = (searchText, cancelToken = null) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await DataService.get(`/metadata/tag?tag=${searchText}`, cancelToken);
      const response = res.data;
      if (response.status === 200) {
        resolve(response.result);
      } else if (response.hasOwnProperty('error')) {
        reject(response);
      } else {
        reject(response);
      }
    } catch (error) {
      if (!Axios.isCancel(error)) {
        reject(error);
      }
    }
  });
};