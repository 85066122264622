import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
        padding: theme.spacing(3),
    },
    image: {
        height: 240,
        backgroundImage: 'url("/images/undraw_empty_xct9.svg")',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
    },
}));

const EmptyList = props => {
    const { className, ...rest } = props;

    const classes = useStyles();

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <div className={classes.image} />
        </div>
    );
};

EmptyList.propTypes = {
    className: PropTypes.string,
};

export default EmptyList;
