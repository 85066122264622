import React, { useEffect, useState } from 'react';
import {
    Button,
    IconButton,
    Paper,
    Stack,
    Typography,
    TextField,
    LinearProgress,
} from '@mui/material';
import { TimePicker } from '@mui/lab';
import { DragDrop, StatusBar } from '@uppy/react';
import MinimizeIcon from '@mui/icons-material/Minimize';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseIcon from '@mui/icons-material/Close';
import AntModal from '../Modal';
import Uppy from '@uppy/core';
import Tus from '@uppy/tus';
import { useUppy } from '@uppy/react';
import CloseUploadVideoModal from '../CloseUploadVideoModal';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, doUpdateVideoList_Action } from 'Redux/uploadModal/action';
import SearchContents from 'myComponents/UseFullAutoCompletes/SearchContents';
import AccordionCustom from 'myComponents/Accordion/AccordionCustom';
import toSeconds from 'utils/momentObjectToSeconds';
import SearchLanguages from 'myComponents/UseFullAutoCompletes/SearchLanguages';
import '@uppy/core/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import { checkVideoUploadComplete, postFileBeforeUpload, updateVideoSourceData_API, uploadEndpoint } from 'api/Video/videoApi';
import getTusUploadId from 'utils/getTusUploadID';
import { useBeforeunload } from 'react-beforeunload';
import { authHeader } from 'utils/DataService/api';
import Uploader from './VideoUploader';
import { t } from 'i18next';
import moment from 'moment';
import { toastError } from 'components/Toasts/Toasts';

const UploadVideoModal = props => {
    const secondToInputType = (value) => {
        if (!value) {
            return null;
        }
        else {
            return {
                moment: moment(`2022/02/02 ${moment.utc(value * 1000).format('HH:mm:ss')}`),
                second: value
            }
        }
    }

    const dispatch = useDispatch();
    const defaultValue = useSelector(state => state.uploadModal.defaultValue);

    const [modalWindow, setModalWindow] = useState('NORMAL');
    const [makeSureCloseModal, setMakeSureCloseModal] = useState(false);
    const [uploadStarted, setUploadStarted] = useState(false);
    const [form, setForm] = useState({
        videoContent: defaultValue?.content ?? null,
        videoLanguage: defaultValue?.language ?? null,
        title: defaultValue?.name ?? '',
        duration: secondToInputType(defaultValue?.duration)?.second ?? null,
        timeOfTeaserStart: secondToInputType(defaultValue?.startTeaser)?.second ?? null,
        timeOfTeaserEnd: secondToInputType(defaultValue?.endTeaser)?.second ?? null,
        timeOfShowNextButton: secondToInputType(defaultValue?.startNextEpisodeButton)?.second ?? null,
    });

    const uppy = useUppy(() => {
        return new Uppy({
            id: 'Video',
            autoProceed: false, // for Start Upload after user select file
            // onBeforeUpload: file => file,
        })
            .use(Tus, {
                endpoint: uploadEndpoint,
                headers: authHeader(),
                retryDelays: [0, 1000, 3000, 5000],
            })
            .on('file-added', file => {
                setUploadStarted(true);
            })
            .on('complete', file => {
                const tusId = getTusUploadId(file);
                checkVideoUploadComplete(tusId)
                    .then(res => {
                        dispatch(closeModal());
                        dispatch(doUpdateVideoList_Action());
                        setUploadStarted(false);
                    })
                    .catch(err => err);
            })
            .on('cancel-all', () => setUploadStarted(false));
    });

    const minimize = () => {
        setModalWindow('MINIMIZE');
    };

    const maximize = () => {
        setModalWindow('NORMAL');
    };

    const confirmationModal = () => {
        setMakeSureCloseModal(prev => !prev);
    };

    const handleForm = (name, value) => {
        setForm({
            ...form,
            [name]: value,
        });

        if (name === 'videoLanguage' || name === 'videoContent') {
            uppy.setMeta({ [name + 'Id']: value?.id });
        }
        else {
            uppy.setMeta({ [name]: value });
        }
    };

    const handleEditVideoSource = () => {
        let errors = [];

        if (!form.title || form.title.toString().trim().length === 0) {
            errors.push(t("Title is required"));
        }
        if (!form.videoLanguage) {
            errors.push(t("Language is required"));
        }

        if (errors.length !== 0) {
            errors.map(toastError);
        }
        else {
            updateVideoSourceData_API(defaultValue.id, {
                videoContentId: form?.videoContent?.id ?? 0,
                languageId: form.videoLanguage.id,
                title: form.title,
                startTeaser: form.timeOfTeaserStart ?? 0,
                endTeaser: form.timeOfTeaserEnd ?? 0,
                startNextEpisodeButton: form.timeOfShowNextButton ?? 0,
            })
                .then(response => {
                    dispatch(closeModal());
                    dispatch(doUpdateVideoList_Action())
                })
                .catch(err => {

                })
        }
    }

    useBeforeunload(event => {
        if (uploadStarted) {
            event.preventDefault();
        }
    });

    return (
        <AntModal
            visible
            uploadModal
            fullWidth={modalWindow === 'NORMAL'}
            maxWidth="sm"
            okButtonProps={{ sx: { display: 'none' } }}
            onBackdropClick={uploadStarted ? confirmationModal : () => dispatch(closeModal())}
            onCancel={uploadStarted ? confirmationModal : () => dispatch(closeModal())}
            onCancelText={uploadStarted ? t('Cancel Upload') : t('Cancel')}
            cancelButtonProps={{ color: uploadStarted ? 'error' : 'primary' }}
            hideBackDrop={modalWindow === 'MINIMIZE'}
            isDraggable={modalWindow === 'MINIMIZE'}
            noAction={modalWindow === 'MINIMIZE'}
            titleFlexDirection="column"
            title={
                <Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography
                            fontWeight="bold"
                            sx={{ marginRight: 2, flexDirection: 'column' }}
                        >
                            {
                                defaultValue?.id
                                    ? t("Edit Video")
                                    : (uploadStarted ? t('Uploading Video') : t('Upload Video'))
                            }
                        </Typography>
                        <Stack>
                            {modalWindow === 'NORMAL' ? (
                                uploadStarted ? (
                                    <IconButton onClick={minimize} size="small" edge="start">
                                        <MinimizeIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        onClick={
                                            uploadStarted
                                                ? confirmationModal
                                                : () => dispatch(closeModal())
                                        }
                                        size="small"
                                        LinkComponent="hello"
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                )
                            ) : (
                                <Stack direction="row" justifyContent="space-evenly">
                                    <IconButton onClick={maximize} size="small">
                                        <OpenInFullIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                        onClick={
                                            uploadStarted
                                                ? confirmationModal
                                                : () => dispatch(closeModal())
                                        }
                                        size="small"
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            }
        >
            <Stack>
                {modalWindow === 'NORMAL' && (
                    <Stack>
                        <SearchContents
                            disabled={uploadStarted}
                            defaultValue={defaultValue?.content}
                            helperTextOfContent={defaultValue ? null : t('Content Must Selected')}
                            error={!Boolean(form.videoContent)}
                            value={form.videoContent}
                            onChange={(_, data) => handleForm('videoContent', data)}
                            label={t('Content')}
                        />
                        {/* <Countries multiple={true} onChange={(_, data) => handleForm('videoLanguageId', data)} /> */}

                        <SearchLanguages
                            disabled={uploadStarted}
                            helpertext={
                                form.videoLanguage ? null : t('Language Of Video Must Selected')
                            }
                            error={!Boolean(form.videoLanguage)}
                            value={form.videoLanguage}
                            onChange={(_, data) => handleForm('videoLanguage', data)}
                            label={t('Video Language')}
                        />

                        <TextField
                            label={t('Title')}
                            fullWidth
                            sx={{
                                mt: 1,
                            }}
                            value={form.title}
                            onChange={e => handleForm('title', e.target.value)}
                        />

                        <AccordionCustom
                            title={t('Add More Details')}
                            titleColor="primary"
                            iconColor="primary"
                            grow
                        >
                            <Stack sx={{ width: '100%' }} justifyContent="space-between">
                                <Stack direction="row" justifyContent="space-between">
                                    {/* <Stack sx={{ width: '49%' }}>
                                        <TimePicker
                                            disableOpenPicker
                                            defaultValue={secondToInputType(defaultValue?.duration)?.moment}
                                            value={form.duration}
                                            ampm={false}
                                            openTo="hours"
                                            views={['hours', 'minutes', 'seconds']}
                                            inputFormat="HH:mm:ss"
                                            mask="__:__:__"
                                            label="Duration of Content"
                                            onChange={data =>
                                                data ? handleForm('duration', toSeconds(data)) : null
                                            }
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    error={false}
                                                    fullWidth
                                                    margin="dense"
                                                    placeholder="Type or Choose from Timepicker"
                                                />
                                            )}
                                        />
                                    </Stack> */}
                                    <Stack sx={{ width: '49%' }}>
                                        <TimePicker
                                            disableOpenPicker
                                            ampm={false}
                                            value={secondToInputType(defaultValue?.startNextEpisodeButton)?.moment ?? null}
                                            openTo="hours"
                                            views={['hours', 'minutes', 'seconds']}
                                            inputFormat="HH:mm:ss"
                                            mask="__:__:__"
                                            label="Time To Show Next Content Button"
                                            onChange={data =>
                                                data
                                                    ? handleForm('timeOfShowNextButton', toSeconds(data))
                                                    : handleForm('timeOfShowNextButton', 0)
                                            }
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    helperText={
                                                        form.timeOfShowNextButton <
                                                        form.timeOfTeaserEnd &&
                                                        'Time To Show Next Content Button must be After the End Teaser Time'
                                                    }
                                                    fullWidth
                                                    margin="dense"
                                                    error={
                                                        form.timeOfShowNextButton <
                                                        form.timeOfTeaserEnd
                                                    }
                                                />
                                            )}
                                        />
                                    </Stack>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Stack sx={{ width: '49%' }}>
                                        <TimePicker
                                            disableOpenPicker
                                            ampm={false}
                                            value={secondToInputType(defaultValue?.startTeaser)?.moment ?? null}
                                            openTo="hours"
                                            views={['hours', 'minutes', 'seconds']}
                                            inputFormat="HH:mm:ss"
                                            mask="__:__:__"
                                            label="Start Time of first Teaser"
                                            onChange={data =>
                                                data
                                                    ? handleForm('timeOfTeaserStart', toSeconds(data))
                                                    : handleForm('timeOfTeaserStart', 0)
                                            }
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    error={false}
                                                    fullWidth
                                                    margin="dense"
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack sx={{ width: '49%' }}>
                                        <TimePicker
                                            disableOpenPicker
                                            ampm={false}
                                            value={secondToInputType(defaultValue?.endTeaser)?.moment ?? null}
                                            openTo="hours"
                                            views={['hours', 'minutes', 'seconds']}
                                            inputFormat="HH:mm:ss"
                                            mask="__:__:__"
                                            label="End Time of first Teaser"
                                            onChange={data =>
                                                data
                                                    ? handleForm('timeOfTeaserEnd', toSeconds(data))
                                                    : handleForm('timeOfTeaserEnd', 0)
                                            }
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    helperText={
                                                        form.timeOfTeaserEnd <
                                                        form.timeOfTeaserStart &&
                                                        'the End Time must be After the First Time'
                                                    }
                                                    fullWidth
                                                    margin="dense"
                                                    error={
                                                        form.timeOfTeaserEnd <
                                                        form.timeOfTeaserStart
                                                    }
                                                />
                                            )}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </AccordionCustom>
                    </Stack>
                )}

                {
                    defaultValue?.id
                        ?
                        <Button onClick={handleEditVideoSource} variant="contained">
                            {t("Submit")}
                        </Button>
                        :
                        <Uploader
                            uppy={uppy}
                            uploadStarted={uploadStarted}
                            disabled={!(form.videoContent && form.videoLanguage)}
                            fullWidth
                            accept="video/*"
                        />
                }
            </Stack>
            {makeSureCloseModal ? (
                <CloseUploadVideoModal visible onCancel={confirmationModal} />
            ) : null}
        </AntModal>
    );
};

export default UploadVideoModal;
