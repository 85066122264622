import React, { useEffect, useRef, useState } from 'react';
import { DragDrop, ProgressBar, StatusBar, FileInput } from '@uppy/react';
import '@uppy/core/dist/style.css';
import '@uppy/file-input/dist/style.css';
import { Button, Stack, styled } from '@mui/material';
import '@uppy/core/dist/style.css';
import '@uppy/progress-bar/dist/style.css';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/core/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import { toastError } from 'components/Toasts/Toasts';

const Input = styled('input')({
    display: 'none',
});

const Uploader = props => {
    const { uppy, uploadStarted, multiple, disabled, fullWidth, accept } = props;

    const inputRef = useRef();

    const onInputFileChanged = file => {
        const files = Array.from(file);
        files.forEach(file => {
            try {
                uppy.addFile({
                    source: 'file input',
                    name: file.name,
                    type: file.type,
                    data: file,
                });
            } catch (err) {
                if (err.isRestriction) {
                    // handle restrictions
                    toastError('Already Exist');
                } else {
                    // handle other errors
                    console.error(err);
                }
            }
        });
    };

    uppy.on('complete', () => {
        inputRef.current.value = null;
    });

    uppy.on('error', () => {
        inputRef.current.value = null;
    });

    return (
        <Stack sx={{ my: uploadStarted ? 1 : 0 }}>
            {/* {!uploadStarted && <DragDrop uppy={uppy} />} */}
            {!uploadStarted && (
                <label htmlFor="my-file-input">
                    <Input
                        ref={inputRef}
                        accept={accept}
                        id="my-file-input"
                        multiple={multiple}
                        type="file"
                        onChange={e => onInputFileChanged(e.target.files)}
                        disabled={disabled}
                    />
                    <Button
                        variant="contained"
                        component="span"
                        disabled={disabled}
                        fullWidth={fullWidth}
                        sx={{ mt: 2 }}
                    >
                        Choose File
                    </Button>
                </label>
            )}

            <StatusBar uppy={uppy} />
            {/* <ProgressBar uppy={uppy} /> */}
        </Stack>
    );
};

export default Uploader;
