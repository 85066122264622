import actionTypes from './actionTypes';

const { SET_STATAIC_PERMISSIONS, SET_SITE_LANGUAGE, SET_CONFIGURATION } = actionTypes;

const INITAIL_STATE = {
    staticPermissions: null,
    vodConfiguration: {},
    siteLanguage: {
        dir: 'ltr',
        lang: 'en',
    },
};

export const generalDataReducer = (state = INITAIL_STATE, { type, payload }) => {
    switch (type) {
        case SET_STATAIC_PERMISSIONS:
            return {
                ...state,
                staticPermissions: payload,
            };
        case SET_SITE_LANGUAGE:
            return {
                ...state,
                siteLanguage: payload,
            };
        case SET_CONFIGURATION:
            return {
                ...state,
                vodConfiguration: payload,
            };
        default:
            return state;
    }
};
