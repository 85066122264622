import React from "react";
import { ThemeProvider } from '@mui/material';
import { createTheme, adaptV4Theme } from '@mui/material';

import palette from './palette';
import typography from './typography';
import components from './overrides';
import { useSelector } from 'react-redux';

function MyTheme(props){
    const rtlTheme = createTheme({
        palette,
        typography: {
            ...typography,
            fontFamily: ["arabic"]
        },
        components,
        direction: 'rtl',
    });

    const ltrTheme = createTheme({
        palette,
        typography,
        components,
        direction: 'ltr',
    });

    const { siteLanguage } = useSelector(state => state.generalData);

    if(siteLanguage.dir === 'rtl'){
        document.querySelector('body').classList.add("rtl")
    }
    else{
        document.querySelector('body').classList.remove("rtl")
    }

    return (
        <ThemeProvider theme={siteLanguage.dir === 'rtl' ? rtlTheme : ltrTheme}>
            { props.children }
        </ThemeProvider>
    )
}

export default MyTheme;