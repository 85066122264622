import React from 'react';
import { Stack, Typography } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

import AntModal from './Modal';
import { closeModal } from 'Redux/uploadModal/action';
import { useDispatch } from 'react-redux';

const CloseUploadVideoModal = props => {
  const dispatch = useDispatch();
  const { visible, onCancel } = props;
  const handleOk = () => {
    onCancel();
    dispatch(closeModal());
  };
  return (
    <AntModal
      visible={visible}
      title={
        <Stack direction="row" alignItems="center">
          <InfoOutlined color="error" fontSize="medium" sx={{ marginRight: 1 }} />
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Cancel Upload
          </Typography>
        </Stack>
      }
      onCancel={onCancel}
      onCancelText="No"
      cancelButtonProps={{
        variant: 'outlined',
        color: 'primary',
      }}
      onOk={handleOk}
      onOkText="Yes"
      okButtonProps={{
        variant: 'outlined',
        color: 'error',
      }}
    >
      if You Close this Modal, the Upload Will Canceled! <br /> Are you Sure About it ?
    </AntModal>
  );
};

export default CloseUploadVideoModal;
